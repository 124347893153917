// dataSaga.ts
import { call, put, all, takeLatest, select } from "redux-saga/effects";
import {
  fetchContactSuccess,
  fetchContactFailure,
  saveContactCategorySuccess,
  sendEventConfirmationSuccess,
  sendEventConfirmationFailure,
  fetchCategorySuccess,
  fetchCategoryFailure,
} from "../../actions/contacts";

import {
  FETCH_CONTACT_REQUEST,
  FETCH_CATEGORY_REQUEST,
  SAVE_CONTACT_CATEGORY_REQUEST,
  SEND_EVENT_CONFIRMATION_REQUEST,
} from "../../../constants/redux";
import { commonApiCall } from "../../apiCaller";
import { ShowAlert } from "../../actions/alert";
import { getUserIdFromState } from "../../authUtils";
import { COMMON_ERROR_MESSAGE, COMMON_SAVE_ERROR_MESSAGE, COMMON_SUCCESSFULL_MESSAGE, COMMON_SUCCESSFULL_SENT } from "../../../constants/redux/messages";

type GeneratorReturnType<T> = Generator<any, T, any>;
const dashboardPath = "";

function* fetchContactSaga(): GeneratorReturnType<void> {
  try {
    const response = yield call(
      commonApiCall,
      `${dashboardPath}/contacts`,
      "GET",
      {},
      {}
    );

    yield put(fetchContactSuccess(response));
  } catch (error: any) {
    yield put(fetchContactFailure(error.message));
    yield put(
      ShowAlert({
        showAlert: true,
        message: error.message || COMMON_ERROR_MESSAGE,
        type: "error",
      })
    );
  }
}

function* SaveContactCategorySaga(action: any): GeneratorReturnType<void> {
  try {
    const response: any = yield call(
      commonApiCall,
      `${dashboardPath}/contacts`,
      "PUT",
      action.payload,
      {}
    );
    yield put(saveContactCategorySuccess(action.payload));
    yield put(
      ShowAlert({
        showAlert: true,
        message: response.message || COMMON_SUCCESSFULL_MESSAGE,
        type: "success",
      })
    );
  } catch (error: any) {
    yield put(fetchContactFailure(error.message));
    yield put(
      ShowAlert({
        showAlert: true,
        message: error.message || COMMON_SAVE_ERROR_MESSAGE,
        type: "error",
      })
    );
  }
}

function* sendEventConfirmation(action: any): GeneratorReturnType<void> {
  try {
    const response: any = yield call(
      commonApiCall,
      `${dashboardPath}/email-invite`,
      "PUT",
      action.payload,
      {}
    );

    yield put(sendEventConfirmationSuccess());
    yield put(
      ShowAlert({
        showAlert: true,
        message: response.message || COMMON_SUCCESSFULL_SENT,
        type: "success",
      })
    );
  } catch (error: any) {
    yield put(sendEventConfirmationFailure(error));
    yield put(
      ShowAlert({
        showAlert: true,
        message: error.message || COMMON_SAVE_ERROR_MESSAGE,
        type: "error",
      })
    );
  }
}

function* fetchCategorySaga(action: any): GeneratorReturnType<void> {
  try {
    const response = yield call(
      commonApiCall,
      `${dashboardPath}/categories`,
      "GET",
      {},
      {}
    );

    yield put(fetchCategorySuccess(response));
  } catch (error: any) {
    yield put(fetchCategoryFailure(error.message));
    yield put(
      ShowAlert({
        showAlert: true,
        message: error.message || COMMON_ERROR_MESSAGE,
        type: "error",
      })
    );
  }
}

function* watchSaveContactCategoryData() {
  yield takeLatest(SAVE_CONTACT_CATEGORY_REQUEST, SaveContactCategorySaga);
}

function* watchFetchData() {
  yield takeLatest(FETCH_CONTACT_REQUEST, fetchContactSaga);
}

function* watchSendEventConfirmation() {
  yield takeLatest(SEND_EVENT_CONFIRMATION_REQUEST, sendEventConfirmation);
}

function* watchFetchCategory() {
  yield takeLatest(FETCH_CATEGORY_REQUEST, fetchCategorySaga);
}

export function* contactSaga() {
  yield all([
    watchFetchData(),
    watchSaveContactCategoryData(),
    watchSendEventConfirmation(),
    watchFetchCategory(),
  ]);
}
