import {
  FETCH_CONTACT_REQUEST,
  FETCH_CONTACT_SUCCESS,
  FETCH_CONTACT_FAILURE,
  FETCH_CATEGORY_REQUEST,
  FETCH_CATEGORY_SUCCESS,
  FETCH_CATEGORY_FAILURE,
  SAVE_CONTACT_CATEGORY_REQUEST,
  SAVE_CONTACT_CATEGORY_SUCCESS,
  SEND_EVENT_CONFIRMATION_REQUEST,
  SEND_EVENT_CONFIRMATION_FAILURE,
  SEND_EVENT_CONFIRMATION_SUCCESS,
} from "../../../constants/redux";

export const fetchContactRequest = (params?: Record<string, any>) => ({
  type: FETCH_CONTACT_REQUEST,
  payload: { params },
});

export const fetchContactSuccess = (data: any) => ({
  type: FETCH_CONTACT_SUCCESS,
  payload: data,
});

export const fetchContactFailure = (error: string) => ({
  type: FETCH_CONTACT_FAILURE,
  payload: error,
});

export const fetchCategoryRequest = (params?: Record<string, any>) => ({
  type: FETCH_CATEGORY_REQUEST,
  payload: { params },
});

export const fetchCategorySuccess = (data: any) => ({
  type: FETCH_CATEGORY_SUCCESS,
  payload: data,
});

export const fetchCategoryFailure = (error: string) => ({
  type: FETCH_CATEGORY_FAILURE,
  payload: error,
});

export const saveContactCategoryRequest = (params?: any) => ({
  type: SAVE_CONTACT_CATEGORY_REQUEST,
  payload: params ,
});

export const saveContactCategorySuccess = (data: any) => ({
  type: SAVE_CONTACT_CATEGORY_SUCCESS,
  payload: data,
});

export const sendEventConfirmationRequest = (eventId: number) => ({
  type: SEND_EVENT_CONFIRMATION_REQUEST,
  payload: {
    eventId,
  },
});

export const sendEventConfirmationSuccess = () => ({
  type: SEND_EVENT_CONFIRMATION_SUCCESS,
});

export const sendEventConfirmationFailure = (error: string) => ({
  type: SEND_EVENT_CONFIRMATION_FAILURE,
  error,
});