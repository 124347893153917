import axios, { AxiosInstance } from "axios";

const api: AxiosInstance = axios.create({
  baseURL: "https://av6ccmjtye.execute-api.us-east-1.amazonaws.com/dev", // Replace with your API base URL
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");

    config.headers["x-api-key"] =`${process.env.REACT_APP_X_API_KEY}`

    if (config.headers && config.headers.Authorization) {
      let value = config.headers.Authorization
      config.headers.Authorization = value;
    } else if (token) {
      config.headers.Authorization = `${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const commonApiCall = async (
  endpoint: string,
  method: string,
  data?: any,
  queryParams?: {},
  header?: {},
  useFormUrlEncoded?: boolean // New parameter to determine form-urlencoding
) => {
  try {
    const response = await api.request({
      url: queryParams ? buildUrlWithParams(endpoint, queryParams) : endpoint,
      method,
      data: data,
      headers: header,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const buildUrlWithParams = (url: string, params?: Record<string, string>) => {
  if (!params) return url;

  const queryString = Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join("&");

  return `${url}?${queryString}`;
};
