import {
  FETCH_CONTACT_REQUEST,
  FETCH_CONTACT_SUCCESS,
  FETCH_CONTACT_FAILURE,
  FETCH_CATEGORY_REQUEST,
  FETCH_CATEGORY_SUCCESS,
  FETCH_CATEGORY_FAILURE,
  SAVE_CONTACT_CATEGORY_SUCCESS,
  SEND_EVENT_CONFIRMATION_FAILURE,
} from "../../../constants/redux";

const initialState: any = {};

const reducers = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_CONTACT_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_CONTACT_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case SAVE_CONTACT_CATEGORY_SUCCESS:{
      const { eventId, id:customerId, selectedOption, note } = action.payload;

      // Find the event by ID
      const updatedEvents = state.data.map((event: any )=> {
        if (event.eventId === eventId) {
          // Find the customer by ID in the selected event
          const updatedSessionCustomers = event.sessionCustomers.map((customer: any) => {
            if (customer.session_id === customerId) {
              // Update the category and additional_note values
              return {
                ...customer,
                category:selectedOption,
                additional_note: note,
              };
            }
            return customer;
          });

          // Update the event with the modified sessionCustomers
          return {
            ...event,
            sessionCustomers: updatedSessionCustomers,
          };
        }
        return event;
      });
      return { ...state, loading: false, data: updatedEvents };

    }
    case FETCH_CATEGORY_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_CATEGORY_SUCCESS:
      return { ...state, loading: false, category: action.payload };
    case FETCH_CATEGORY_FAILURE:{
      return { ...state, loading: false, category: action.payload };
    }
    case FETCH_CONTACT_FAILURE:
    case SEND_EVENT_CONFIRMATION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return {
        ...state,
      };
  }
};

export default reducers;
