import { createStore, applyMiddleware, Middleware } from "redux";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from "./reducers";
import { rootSaga } from "./saga";

const initialState = {};
// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

type LoggerMiddleware = Middleware<{}, any, any>;

// Mount it on the Store
const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(sagaMiddleware, logger as LoggerMiddleware)));

// Run the saga
sagaMiddleware.run(rootSaga);

export default store;