import {
  FETCH_NOTIFICATION_REQUEST,
  FETCH_NOTIFICATION_SUCCESS,
  FETCH_NOTIFICATION_FAILURE,
  DELETE_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION_SUCCESS,
  READ_SINGLE_NOTIFICATION_REQUEST,
  READ_SINGLE_NOTIFICATION_SUCCESS,
  READ_SINGLE_NOTIFICATION_FAILURE,
} from "../../../constants/redux";

const initialState: any = {};

const reducers = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_NOTIFICATION_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_NOTIFICATION_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case FETCH_NOTIFICATION_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case DELETE_NOTIFICATION_SUCCESS: {
      const updatedValues = action.payload.length === 1 && state.data && state.data.filter((item: { id: any; }) => item.id !== action.payload[0]);
      return { ...state, loading: false, data: updatedValues };
    }
    case READ_NOTIFICATION_SUCCESS:
      const readNotifications = state.data.map((obj: any) => ({
        ...obj,
        isRead: true,
      }));
      return { ...state, loading: false, data: readNotifications };
    case READ_SINGLE_NOTIFICATION_REQUEST:
      return { ...state, loading: true, error: null };
    case READ_SINGLE_NOTIFICATION_SUCCESS:
      const updatedNotifications = state.data.map((obj: any) =>
        obj.id === action.payload.id
          ? {
            ...obj,
            ...{ isRead: action.payload.status },
          }
          : obj
      );
      return { ...state, loading: false, data: updatedNotifications };
    case READ_SINGLE_NOTIFICATION_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return {
        ...state,
      };
  }
};

export default reducers;
