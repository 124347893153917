export const ENABLE_RIGHT_BAR = "ENABLE_RIGHT_BAR";

export const FETCH_EVENT_REQUEST = "FETCH_EVENT_REQUEST";
export const FETCH_COMPLETED_EVENT_REQUEST = "FETCH_COMPLETED_EVENT_REQUEST";
export const FETCH_EVENT_SUCCESS = "FETCH_EVENT_SUCCESS";
export const FETCH_COMPLETED_EVENT_SUCCESS = "FETCH_COMPLETED_EVENT_SUCCESS";
export const FETCH_EVENT_FAILURE = "FETCH_EVENT_FAILURE";

export const CONFIRM_AVAILABILITY_REQUEST = "CONFIRM_AVAILABILITY_REQUEST";
export const CONFIRM_AVAILABILITY_SUCCESS = "CONFIRM_AVAILABILITY_SUCCESS";
export const CONFIRM_AVAILABILITY_FAILURE = "CONFIRM_AVAILABILITY_FAILURE";

export const DECLINE_SESSION_REQUEST = "DECLINE_SESSION_REQUEST";
export const DECLINE_SESSION_SUCCESS = "DECLINE_SESSION_SUCCESS";
export const DECLINE_SESSION_FAILURE = "DECLINE_SESSION_FAILURE";

export const FETCH_EVENT_BY_ID_REQUEST = "FETCH_EVENT_BY_ID_REQUEST";
export const FETCH_EVENT_BY_ID_SUCCESS = "FETCH_EVENT_BY_ID_SUCCESS";
export const FETCH_EVENT_BY_ID_FAILURE = "FETCH_EVENT_BY_ID_FAILURE";
export const FETCH_EVENT_BY_ID_UNDO = "FETCH_EVENT_BY_ID_UNDO"

export const FETCH_SESSION_DETAILS_REQUEST = "FETCH_SESSION_DETAILS_REQUEST";
export const FETCH_SESSION_DETAILS_SUCCESS = "FETCH_SESSION_DETAILS_SUCCESS";
export const FETCH_SESSION_DETAILS_FAILURE = "FETCH_SESSION_DETAILS_FAILURE";

export const ADD_IMAGE_DETAILS_REQUEST = 'ADD_IMAGE_DETAILS_REQUEST';
export const ADD_IMAGE_DETAILS_SUCCESS = 'ADD_IMAGE_DETAILS_SUCCESS';
export const ADD_IMAGE_DETAILS_FAILURE = 'ADD_IMAGE_DETAILS_FAILURE';

export const FETCH_IMAGES_REQUEST = 'FETCH_IMAGES_REQUEST';
export const FETCH_IMAGES_SUCCESS = 'FETCH_IMAGES_SUCCESS';
export const FETCH_IMAGES_FAILURE = 'FETCH_IMAGES_FAILURE';

export const DELETE_SELECTED_IMAGES_REQUEST = 'DELETE_SELECTED_IMAGES_REQUEST';
export const DELETE_SELECTED_IMAGES_SUCCESS = 'DELETE_SELECTED_IMAGES_SUCCESS';
export const DELETE_SELECTED_IMAGES_FAILURE = 'DELETE_SELECTED_IMAGES_FAILURE';


export const SAVE_SURVEY_REQUEST = 'SAVE_SURVEY_REQUEST';
export const SAVE_SURVEY_SUCCESS = 'SAVE_SURVEY_SUCCESS';
export const SAVE_SURVEY_FAILURE = 'SAVE_SURVEY_FAILURE';

//Invited events
export const FETCH_INVITED_EVENTS_REQUEST = 'FETCH_INVITED_EVENTS_REQUEST';
export const FETCH_INVITED_EVENTS_SUCCESS = 'FETCH_INVITED_EVENTS_SUCCESS';
export const FETCH_INVITED_EVENTS_FAILURE = 'FETCH_INVITED_EVENTS_FAILURE';

export const ACCEPT_EVENT_REQUEST = 'ACCEPT_EVENT_REQUEST';
export const ACCEPT_EVENT_SUCCESS = 'ACCEPT_EVENT_SUCCESS';
export const ACCEPT_EVENT_FAILURE = 'ACCEPT_EVENT_FAILURE';

export const DECLINE_EVENT_REQUEST = 'DECLINE_EVENT_REQUEST';
export const DECLINE_EVENT_SUCCESS = 'DECLINE_EVENT_SUCCESS';
export const DECLINE_EVENT_FAILURE = 'DECLINE_EVENT_FAILURE';


// Contacts
export const FETCH_CONTACT_REQUEST = "FETCH_CONTACT_REQUEST";
export const FETCH_CONTACT_SUCCESS = "FETCH_CONTACT_SUCCESS";
export const FETCH_CONTACT_FAILURE = "FETCH_CONTACT_FAILURE";

export const SAVE_CONTACT_CATEGORY_REQUEST = "SAVE_CONTACT_CATEGORY_REQUEST";
export const SAVE_CONTACT_CATEGORY_SUCCESS = "SAVE_CONTACT_CATEGORY_SUCCESS";

// ActionTypes.ts
export const SEND_EVENT_CONFIRMATION_REQUEST = 'SEND_EVENT_CONFIRMATION_REQUEST';
export const SEND_EVENT_CONFIRMATION_SUCCESS = 'SEND_EVENT_CONFIRMATION_SUCCESS';
export const SEND_EVENT_CONFIRMATION_FAILURE = 'SEND_EVENT_CONFIRMATION_FAILURE';


// Galleries
export const FETCH_GALLERY_REQUEST = "FETCH_GALLERY_REQUEST";
export const FETCH_GALLERY_SUCCESS = "FETCH_GALLERY_SUCCESS";
export const FETCH_GALLERY_FAILURE = "FETCH_GALLERY_FAILURE";

// Notification
export const FETCH_NOTIFICATION_REQUEST = "FETCH_NOTIFICATION_REQUEST";
export const FETCH_NOTIFICATION_SUCCESS = "FETCH_NOTIFICATION_SUCCESS";
export const FETCH_NOTIFICATION_FAILURE = "FETCH_NOTIFICATION_FAILURE";

export const DELETE_NOTIFICATION_REQUEST = "DELETE_NOTIFICATION_REQUEST";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAILURE = "DELETE_NOTIFICATION_FAILURE";

export const READ_NOTIFICATION_REQUEST = "READ_NOTIFICATION_REQUEST";
export const READ_NOTIFICATION_SUCCESS = "READ_NOTIFICATION_SUCCESS";
export const READ_NOTIFICATION_FAILURE = "READ_NOTIFICATION_FAILURE";

//Profile
export const SET_PROFILE = "SET_PROFILE";
export const SET_PROFILE_SUCCESS = "SET_PROFILE_SUCCESS";
export const SET_PROFILE_FAILURE = "SET_PROFILE_FAILURE";

//Profile- Personal Details Requests
export const ADD_PERSONAL_DETAILS_REQUEST = "ADD_PERSONAL_DETAILS_REQUEST";
export const ADD_PERSONAL_DETAILS_SUCCESS = "ADD_PERSONAL_DETAILS_SUCCESS";
export const ADD_PERSONAL_DETAILS_FAILURE = "ADD_PERSONAL_DETAILS_FAILURE";

export const UPLOAD_PROFILE_PICTURE_REQUEST = 'UPLOAD_PROFILE_PICTURE_REQUEST';
export const UPLOAD_PROFILE_PICTURE_SUCCESS = 'UPLOAD_PROFILE_PICTURE_SUCCESS';
export const UPLOAD_PROFILE_PICTURE_FAILURE = 'UPLOAD_PROFILE_PICTURE_FAILURE';

//Profile - Password change
export const ADD_PASSWORD_REQUEST = "ADD_PASSWORD_REQUEST";
export const ADD_PASSWORD_SUCCESS = "ADD_PASSWORD_SUCCESS";
export const ADD_PASSWORD_FAILURE = "ADD_PASSWORD_FAILURE";

//Profile - Mobile Number Verification
export const MOBILE_NUMBER_VERIFICATION_REQUEST = "MOBILE_NUMBER_VERIFICATION_REQUEST";
export const MOBILE_NUMBER_VERIFICATION_SUCCESS = "MOBILE_NUMBER_VERIFICATION_SUCCESS";
export const MOBILE_NUMBER_VERIFICATION_FAILURE = "MOBILE_NUMBER_VERIFICATION_FAILURE";

//Profile - Work Preference
export const ADD_WORK_PREFERENCE_REQUEST = "ADD_WORK_PREFERENCE_REQUEST";
export const ADD_WORK_PREFERENCE_SUCCESS = "ADD_WORK_PREFERENCE_SUCCESS";
export const ADD_WORK_PREFERENCE_FAILURE = "ADD_WORK_PREFERENCE_FAILURE";

//Profile - Equipment
export const ADD_EQUIPMENT_REQUEST = "ADD_EQUIPMENT_REQUEST";
export const FETCH_EQUIPMENT_REQUEST = "FETCH_EQUIPMENT_REQUEST";
export const ADD_EQUIPMENT_SUCCESS = "ADD_EQUIPMENT_SUCCESS";
export const FETCH_EQUIPMENT_SUCCESS = "FETCH_EQUIPMENT_SUCCESS";
export const ADD_EQUIPMENT_FAILURE = "ADD_EQUIPMENT_FAILURE";

//Profile - Schedule
export const ADD_SCHEDULE_REQUEST = "ADD_SCHEDULE_REQUEST";
export const ADD_EXCEPTIONS_REQUEST = "ADD_EXCEPTIONS_REQUEST";
export const ADD_SCHEDULE_SUCCESS = "ADD_SCHEDULE_SUCCESS";
export const ADD_SCHEDULE_FAILURE = "ADD_SCHEDULE_FAILURE";

//Profile - Location and shoot types
export const ADD_LOCATION_REQUEST = "ADD_LOCATION_REQUEST";
export const ADD_ONBOARDING_LOCATION_REQUEST = "ADD_ONBOARDING_LOCATION_REQUEST";
export const FETCH_DEFAULT_LOCATIONS_REQUEST = "FETCH_DEFAULT_LOCATIONS_REQUEST";
export const FETCH_DEFAULT_LOCATIONS_SUCCESS = "FETCH_DEFAULT_LOCATIONS_SUCCESS";
export const ADD_LOCATION_SUCCESS = "ADD_LOCATION_SUCCESS";
export const ADD_LOCATION_FAILURE = "ADD_LOCATION_FAILURE";

export const HANDLE_SELECTED_LOCATION_REQUEST = "HANDLE_SELECTED_LOCATION_REQUEST";


export const ADD_SHOOT_TYPE_REQUEST = "ADD_SHOOT_TYPE_REQUEST";
export const ADD_SHOOT_TYPE_API_REQUEST = "ADD_SHOOT_TYPE_API_REQUEST";
export const ADD_SHOOT_TYPE_SUCCESS = "ADD_SHOOT_TYPE_SUCCESS";
export const ADD_SHOOT_TYPE_FAILURE = "ADD_SHOOT_TYPE_FAILURE";

//Earnings
export const FETCH_EARNINGS_REQUEST = "FETCH_EARNINGS_REQUEST";
export const FETCH_EARNINGS_SUCCESS = "FETCH_EARNINGS_SUCCESS";
export const FETCH_INVOICES_REQUEST = "FETCH_INVOICES_REQUEST";
export const FETCH_INVOICES_SUCCESS = "FETCH_INVOICES_SUCCESS";
export const FETCH_EARNINGS_FAILURE = "FETCH_EARNINGS_FAILURE";

//Auth
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SIGN_IN = 'SIGN_IN';
//Alert
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

//Single notification
export const READ_SINGLE_NOTIFICATION_REQUEST = "READ_SINGLE_NOTIFICATION_REQUEST";
export const READ_SINGLE_NOTIFICATION_SUCCESS = "READ_SINGLE_NOTIFICATION_SUCCESS";
export const READ_SINGLE_NOTIFICATION_FAILURE = "READ_SINGLE_NOTIFICATION_FAILURE";

//Stripe
export const SAVE_STRIPE_ID_REQUEST = 'SAVE_STRIPE_ID_REQUEST';
export const SAVE_STRIPE_ID_SUCCESS = 'SAVE_STRIPE_ID_SUCCESS';
export const SAVE_STRIPE_ID_FAILURE = 'SAVE_STRIPE_ID_FAILURE';

export const SAVE_STRIPE_ACCOUNT_STATUS_REQUEST = 'SAVE_STRIPE_ACCOUNT_STATUS_REQUEST';
export const SAVE_STRIPE_ACCOUNT_STATUS_SUCCESS = 'SAVE_STRIPE_ACCOUNT_STATUS_SUCCESS';
export const SAVE_STRIPE_ACCOUNT_STATUS_FAILURE = 'SAVE_STRIPE_ACCOUNT_STATUS_FAILURE';

export const VERIFY_STRIPE_USER_REQUEST = 'VERIFY_STRIPE_USER_REQUEST';
export const VERIFY_STRIPE_USER_SUCCESS = 'VERIFY_STRIPE_USER_SUCCESS';
export const VERIFY_STRIPE_USER_FAILURE = 'VERIFY_STRIPE_USER_FAILURE';

export const GET_USER_ID_REQUEST = 'GET_USER_ID_REQUEST';
export const GET_USER_ID_SUCCESS = 'GET_USER_ID_SUCCESS';
export const GET_USER_ID_FAILURE = 'GET_USER_ID_FAILURE';

export const GET_ACCOUNT_ID_REQUEST = 'GET_ACCOUNT_ID_REQUEST';
export const GET_ACCOUNT_ID_SUCCESS = 'GET_ACCOUNT_ID_SUCCESS';
export const GET_ACCOUNT_ID_FAILURE = 'GET_ACCOUNT_ID_FAILURE';

export const GET_ACCOUNT_LINK_REQUEST = 'GET_ACCOUNT_LINK_REQUEST';
export const GET_ACCOUNT_LINK_SUCCESS = 'GET_ACCOUNT_LINK_SUCCESS';
export const GET_ACCOUNT_LINK_FAILURE = 'GET_ACCOUNT_LINK_FAILURE';

export const GET_PAYMENT_DETAILS_REQUEST = 'GET_PAYMENT_DETAILS_REQUEST';
export const GET_PAYMENT_DETAILS_SUCCESS = 'GET_PAYMENT_DETAILS_SUCCESS';
export const GET_PAYMENT_DETAILS_FAILURE = 'GET_PAYMENT_DETAILS_FAILURE';

// Categories
export const FETCH_CATEGORY_REQUEST = "FETCH_CATEGORY_REQUEST";
export const FETCH_CATEGORY_SUCCESS = "FETCH_CATEGORY_SUCCESS";
export const FETCH_CATEGORY_FAILURE = "FETCH_CATEGORY_FAILURE";
