import Alert from "@mui/material/Alert";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { HideAlert } from "../../store/actions/alert";

function AlertComponent(props: any) {
  useEffect(() => {
    setTimeout(() => {
      props.HideAlertComponent({ showAlert: false, message: "" });
    }, 3000);
  }, [props.data?.showAlert]);

  return (
    <Alert
      sx={{
        boxShadow:
          "4px 4px 20px -4px rgba(0, 0, 0, 0.05), 0px 2px 12px -4px rgba(0, 0, 0, 0.10)",
      }}
      severity={props.data?.type}
    >
      {props.data?.message}
    </Alert>
  );
}

const mapStateToProps = (state: { alert: any }) => ({
  data: state.alert?.data,
});
const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      HideAlertComponent: HideAlert,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(AlertComponent);
