import { combineReducers } from "redux";

import eventReducer from "./events/reducer";
import notificationReducer from "./notifications/reducer";
import contactsReducer from "./contacts/reducer";
import galleryReducer from "./galleries/reducer";
import profileReducer from "./profile";
import earningsReducer from "./earnings/reducer";

import sideBarReducer from "./sideBar";
import authReducer from "./auth";
import alertReducer from "./alert/reducer";

const rootReducer = combineReducers({
  sidebar: sideBarReducer,
  event: eventReducer,
  contact: contactsReducer,
  gallery: galleryReducer,
  notification: notificationReducer,
  profile: profileReducer,
  alert: alertReducer,
  earnings: earningsReducer,
  auth: authReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
