import { alpha } from "@mui/material/styles";

// SETUP COLORS

export const customColors = {
  dark1: "#2F242C",
  dark2: "#6B6267",
  dark3: alpha("#6B6267", 0.2),
  light1: "#EFEDEB",
  light2: "#F6F4F2",
};

export const grey = {
  0: "#FFFFFF",
  100: "#7B7377",
  200: "#757575",
  300: "#848484",
  400: "#6A6666",
  500: "#919EAB",
  600: "#A9A9A9",
  700: "#B2B2B2",
  800: "#212B36",
  900: "#000000",
};

export const primary = {
  lighter: "#D77171",
  light: "#D77171",
  main: "#D77171",
  dark: "#A95656",
  darker: "#A95656",
  contrastText: "#FFFFFF",
};

export const secondary = {
  lighter: alpha("#4C8577", 0.2),
  light: "#4C8577",
  main: "#4C8577",
  dark: "#355A51",
  darker: "#355A51",
  contrastText: "#FFFFFF",
};

export const common = {
  black: "#000000",
  white: "#FFFFFF",
};

const base = {
  primary,
  secondary,
  customColors,
  grey,
  common,
  divider: alpha(grey[500], 0.2),
};

// ----------------------------------------------------------------------

export function palette() {
  return {
    ...base,
    mode: "light",
  };
}
