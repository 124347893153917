const formatTime = (date: Date): string => {
  const hours24 = date.getHours();
  const hours12 = hours24 % 12 || 12; // Convert 0 to 12
  const hours = hours12.toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}.${minutes}`;
};

// return Today, Yesterday based on the date
export const formatDateTime = (
  inputDate: Date | string,
  isTimeNoNeed?: boolean
) => {
  const date = inputDate instanceof Date ? inputDate : new Date(inputDate);

  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  if (date >= today) {
    return !!isTimeNoNeed ? `Today` : `Today, ${formatTime(date)}`;
  } else if (date >= yesterday) {
    return !!isTimeNoNeed ? `Yesterday` : `Yesterday, ${formatTime(date)}`;
  }

  // Get month and day
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return !!isTimeNoNeed
    ? `${month}/${day}`
    : `${month}/${day}, ${formatTime(date)}`;
};

//Return type: 9:00pm - 10:00pm
export const getTimeRange = (startDate: Date, endDate: Date): string => {
  const startHours = startDate.getHours() % 12 || 12;
  const startMinutes = startDate.getMinutes();
  const startAMPM = startDate.getHours() >= 12 ? "PM" : "AM";
  const formattedStartTime = `${startHours}:${startMinutes
    .toString()
    .padStart(2, "0")} ${startAMPM}`;

  const endHours = endDate.getHours() % 12 || 12;
  const endMinutes = endDate.getMinutes();
  const endAMPM = endDate.getHours() >= 12 ? "PM" : "AM";
  const formattedEndTime = `${endHours}:${endMinutes
    .toString()
    .padStart(2, "0")} ${endAMPM}`;

  const formattedTimeRange = `${formattedStartTime} - ${formattedEndTime}`;

  return formattedTimeRange;
};

//2023-11-19T15:30:00 => 26th August, 2023
export const formatTimestamp = (timestamp: string | number | Date) => {
  const date = new Date(timestamp);
  const day = date.getDate();
  const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
    date
  );
  const year = date.getFullYear();

  const ordinalSuffix = (n: number): string => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };

  const formattedDate = `${ordinalSuffix(day)} ${month}, ${year}`;
  return formattedDate;
};

//2023-11-19T15:30:00 => 26th August. 2023
export const formatTimestampWithDot = (
  timestamp: string | number | Date,
  isFormatShort: boolean,
  separate?: string
) => {
  const date = new Date(timestamp);
  const day = date.getDate();
  const month = new Intl.DateTimeFormat("en-US", {
    month: isFormatShort ? "short" : "long",
  }).format(date);
  const year = date.getFullYear();

  const ordinalSuffix = (n: number): string => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };

  const formattedDate = `${ordinalSuffix(day)} ${month}${
    separate ?? "."
  } ${year}`;
  return formattedDate;
};

//2023-11-19T15:30:00 => 26th Aug (isFormatShort = true)/ 26th August (isFormatShort = false)
export const convertShortTimestamp = (
  timestamp: string | Date,
  isFormatShort?: boolean
): string => {
  const date = new Date(timestamp);
  const day = date.getDate();
  const month = new Intl.DateTimeFormat("en-US", {
    month: isFormatShort ? "short" : "long",
  }).format(date);

  const ordinalSuffix = (n: number): string => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };

  const formattedDate = `${ordinalSuffix(day)} ${month}`;
  return formattedDate;
};

const timeModifier = (date: any, options: {}) =>
  date
    .toLocaleDateString("en-US", options)
    .split(", ")[1]
    .replace(/\s/g, "")
    .toLowerCase();

//return 3:30pm - 5:30pm
export const convertTime = (date: Date | string, endDate: Date | string) => {
  const startDateObject = date instanceof Date ? date : new Date(date);
  const endDateObject = endDate instanceof Date ? endDate : new Date(endDate);

  const options: any = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return `${timeModifier(startDateObject, options)} - ${timeModifier(
    endDateObject,
    options
  )}`;
};

//return 3:30pm - 5:30pm
export const convertTimeWithCustomSeparation = (
  date: Date | string,
  endDate: Date | string,
  separate: string
) => {
  const startDateObject = date instanceof Date ? date : new Date(date);
  const endDateObject = endDate instanceof Date ? endDate : new Date(endDate);

  const options: any = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return `${timeModifier(startDateObject, options)} ${separate} ${timeModifier(
    endDateObject,
    options
  )}`;
};

export const getFilteredData = (earnings: any[]) => {
  const filteredValue = (months: number) => {
    const now = new Date();
    const filtered = earnings && earnings.filter((earning: any) => {
      const earningDate = new Date(earning.dateAndTime || earning.eventDateAndTime);
      const diffInMonths =
        (now.getFullYear() - earningDate.getFullYear()) * 12 +
        now.getMonth() -
        earningDate.getMonth();
      return diffInMonths <= months;
    });
    return filtered;
  };

  return {
    all: earnings,
    currentMonth: filteredValue(0),
    lastThreeMonths: filteredValue(3),
    lastSixMonths: filteredValue(6),
    lastTwelveMonths: filteredValue(12),
  };
};
