import { all, fork } from "redux-saga/effects";

import { eventSaga } from "./events";
import { contactSaga } from "./contacts";
import { notificationSaga } from "./notifications";
import { gallerySaga } from "./galleries";
import { profileSaga } from "./profile";
import { earningsSaga } from "./earnings";
import { authSaga } from "./auth";

/*TODO: it's need to configure newly added Saga here.*/
export function* rootSaga() {
  yield all([
    fork(eventSaga),
    fork(contactSaga),
    fork(notificationSaga),
    fork(gallerySaga),
    fork(profileSaga),
    fork(earningsSaga),
    fork(authSaga),
  ]);
}
