import { useState } from "react";

import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
  Typography,
  Avatar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";

import { leftSideBarRoutes } from "../constants/constants";

import PhotoppzLogo from "../assets/images/photoppzLogo.png";
import { useAuth } from "../routes/auth/useAuth";
import LogoIcon from "../assets/images/LogoIcon.png";
import { connect, useSelector } from "react-redux";
import { profileKeys } from "../constants/profile";
import ProfilePic from "../assets/profile_pic.webp";

const { NAME, IMAGE_URL } = profileKeys.profile;

const drawerWidth = 272;

function LeftBar(props: any) {
  const auth = useAuth();
  const theme = useTheme();
  const [clickedIndex, setClickedIndex] = useState<number | null>(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const logOut = () => {
    hideSideBar();
    auth.isAuthenticated && auth.logout();
  };

  const dashboard = () => {
    navigate("/dashboard");
  };

  const hideSideBar = () => {
    if (isExtraSmallScreen)
      props.closeSideBar()
  }

  const profileImg = useSelector((state: any) => {
    return state.profile?.data?.profile_picture_url;
  });


  return (
    <Drawer
      sx={{
        width: drawerWidth,
        textAlign: "center",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: props?.width,
          boxSizing: "border-box",
        },
        boxShadow: "4px 0px 40px 0px rgba(0, 0, 0, 0.08)",
      }}
      variant="permanent"
      anchor="left"
    >
      <List>
        {props.isLaptop ? (
          <Box
            sx={{
              marginTop: "38px",
              marginBottom: "40px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid container>
              <Grid xs={12}>
                <img
                  src={PhotoppzLogo}
                  loading="lazy"
                  alt="Photoppz Logo"
                  onClick={dashboard}
                  style={{ cursor: "pointer" }}
                />
              </Grid>
              <Grid xs={12} marginTop={"2.3%"}>
                <Typography
                  variant="body_caps_16"
                  fontFamily="fontFamily"
                  sx={{
                    fontStyle: "normal",
                    letterSpacing: "0.32px",
                  }}
                  color={"#6B6267"}
                >
                  Photographer portal
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box
            sx={{
              marginTop: "74px",
              marginBottom: "40px",
              display: "flex",
              alignItems: "center",
              pl: "10px",
              pr: "10px",
            }}
          >
            <img
              src={LogoIcon}
              loading="lazy"
              alt="Photoppz Logo"
              onClick={dashboard}
              style={{ cursor: "pointer" }}
              width="100%"
              height="100%"
            />
          </Box>
        )}

        {leftSideBarRoutes.map((item, index) => {
          const pathNameValue =
            pathname === "/dashboard/events/event-view"
              ? pathname.substring(0, pathname.lastIndexOf("/"))
              : pathname;

          return (
            <>
              <ListItem
                key={item.title}
                disablePadding
                sx={{
                  height: 80,
                  bgcolor: pathNameValue === item.href ? "#4C8577" : "transparent",
                  color: pathNameValue === item.href ? "white" : "#7D7D7D",
                }}
                onClick={() => setClickedIndex(index)}
              >
                <ListItemButton
                  component={Link}
                  to={item.href}
                  onClick={() => item.title === "Sign Out" ? logOut() : hideSideBar()}
                  sx={{
                    height: 80,
                    padding: "0 18%",
                    ":hover": {
                      color: pathNameValue !== item.href ? "#35514A" : "white",
                      background: pathNameValue !== item.href ? "white" : null,
                    },
                    justifyContent: !props.isLaptop ? "center" : "flex-start",
                  }}
                >
                  {item.icon}

                  {props.isLaptop && (
                    <ListItemText sx={{ padding: "0px 20px" }}>
                      <Typography variant="subtitle1">{item.title}</Typography>
                    </ListItemText>
                  )}
                </ListItemButton>
              </ListItem>
            </>
          );
        })}

        {props.isLaptop ? (
          <Grid
            container
            border={"2px #4c857733 solid"}
            sx={{
              backgroundColor: "#4c85773b",
              maxWidth: 239,
              borderRadius: "8px",
              textDecoration: "none",
              display: "flex",
              margin: "15% 5%",
              padding: "6px 16px",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <Grid item>
              <Avatar
                alt="Remy Sharp"
                src={(props.profile && props.profile[IMAGE_URL]) ?? null}
                sx={{ width: 59, height: 59 }}
              />
            </Grid>

            <Grid
              item
              xs
              container
              direction="column"
              alignItems={"start"}
              spacing={0}
            >
              <Grid
                item
                xs
                sx={{ textDecoration: "none" }}
                component={Link}
                to="/dashboard/profile"
                onClick={() => hideSideBar()}
              >
                <Typography
                  gutterBottom
                  variant={"large_body_bold_18"}
                  component="div"
                  color={"secondary.main"}
                  margin={"0 0"}
                >
                  {(props.profile && props.profile[NAME].length > 9 ? props.profile[NAME].substring(0, 9).concat("...") : props.profile && props.profile[NAME]) ?? ""}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container sx={{ p: "5px" }} justifyContent="center">
            <Grid item component={Link} to="/dashboard/profile">
              <Avatar
                alt="Prifile"
                sx={{ width: "50px", height: "50px" }}
              >
                <img
                  src={ProfilePic}
                  alt="Prifile"
                  loading="lazy"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </Avatar>
            </Grid>
          </Grid>
        )}
      </List>
    </Drawer>
  );
}

const mapStateToProps = (state: { profile: any }) => ({
  profile: state.profile.data,
});

export default connect(mapStateToProps, null)(LeftBar);
