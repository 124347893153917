import {
  SAVE_STRIPE_ID_REQUEST,
  SAVE_STRIPE_ID_SUCCESS,
  SAVE_STRIPE_ID_FAILURE,
  SAVE_STRIPE_ACCOUNT_STATUS_FAILURE,
  SAVE_STRIPE_ACCOUNT_STATUS_REQUEST,
  SAVE_STRIPE_ACCOUNT_STATUS_SUCCESS,
  VERIFY_STRIPE_USER_FAILURE,
  VERIFY_STRIPE_USER_REQUEST,
  VERIFY_STRIPE_USER_SUCCESS,
  GET_ACCOUNT_ID_FAILURE,
  GET_ACCOUNT_ID_REQUEST,
  GET_ACCOUNT_ID_SUCCESS,
  GET_ACCOUNT_LINK_FAILURE,
  GET_ACCOUNT_LINK_REQUEST,
  GET_ACCOUNT_LINK_SUCCESS,
  GET_PAYMENT_DETAILS_FAILURE,
  GET_PAYMENT_DETAILS_REQUEST,
  GET_PAYMENT_DETAILS_SUCCESS
} from "../../../constants/redux";

export const saveStripeIdRequest = (stripeId: string) => ({
  type: SAVE_STRIPE_ID_REQUEST,
  payload: { stripeId },
});

export const saveStripeIdSuccess = () => ({
  type: SAVE_STRIPE_ID_SUCCESS,
});

export const saveStripeIdFailure = (error: string) => ({
  type: SAVE_STRIPE_ID_FAILURE,
  payload: error,
});

export const saveStripeAccountStatusRequest = (status: string) => ({
  type: SAVE_STRIPE_ACCOUNT_STATUS_REQUEST,
  payload: { status },
});

export const saveStripeAccountStatusSuccess = () => ({
  type: SAVE_STRIPE_ACCOUNT_STATUS_SUCCESS,
});

export const saveStripeAccountStatusFailure = (error: string) => ({
  type: SAVE_STRIPE_ACCOUNT_STATUS_FAILURE,
  payload: error,
});
//Verify Stripe Account
export const verifyStripeUserRequest = (customerId: string) => ({
  type: VERIFY_STRIPE_USER_REQUEST,
  payload: { customerId },
});

export const verifyStripeUserSuccess = (data: any) => ({
  type: VERIFY_STRIPE_USER_SUCCESS,
  payload: data,
});

export const verifyStripeUserFailure = (error: string) => ({
  type: VERIFY_STRIPE_USER_FAILURE,
  payload: error,
});

export const getAccountIDRequest = () => ({
  type: GET_ACCOUNT_ID_REQUEST,
});

export const getAccountIDSuccess = (accountID: string) => ({
  type: GET_ACCOUNT_ID_SUCCESS,
  payload: { accountID },
});

export const getAccountIDFailure = (error: string) => ({
  type: GET_ACCOUNT_ID_FAILURE,
  payload: { error },
});

export const getAccountLinkRequest = (connectedAccountID: string) => ({
  type: GET_ACCOUNT_LINK_REQUEST,
  payload:  connectedAccountID ,
});

export const getAccountLinkSuccess = (accountLinkURL: string) => ({
  type: GET_ACCOUNT_LINK_SUCCESS,
  payload: { accountLinkURL },
});

export const getAccountLinkFailure = (error: string) => ({
  type: GET_ACCOUNT_LINK_FAILURE,
  payload: { error },
});

export const getPaymentDetailsRequest = (id: string) => ({
  type: GET_PAYMENT_DETAILS_REQUEST,
  payload: {customerId: id}
});

export const getPaymentDetailsSuccess = (paymentDetails: any) => ({
  type: GET_PAYMENT_DETAILS_SUCCESS,
  payload: { paymentDetails },
});

export const getPaymentDetailsFailure = (error: string) => ({
  type: GET_PAYMENT_DETAILS_FAILURE,
  payload: { error },
});
