import { createTheme } from "@mui/material/styles";
import { typography, customTypography } from "./typography";
import { PaletteOptions } from "@mui/material";
import { palette } from "./palette";
import { overrides } from "./overrides";
import { TypographyOptions } from "@mui/material/styles/createTypography";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    large_body_bold_18: true;
    small_body_regular_14: true;
    body_regular_16: true;
    large_body_regular_18:true;
    body_bold_14: true;
    body_caps_16: true;
    tab_text_32: true;
    large_body_regular_18_700: true;
    heading4_bold_32: true;
    heading4_semi_bold_32: true;
    body_caps_semi_bold_16: true;
    small_body_regular_12: true;
    body_bold_18: true;
    poppins_bold_20: true;
    poppins_semi_bold_20: true;
    poppins_regular_20: true;
    small_body_12: true;
    small_body_14: true;
    heading3_regular_24: true;
    poppins_bold_16: true;
    heading4_regular_24: true;
    heading1_bold_48: true;
    poppins_semibold_24: true;
    tab_button: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  large_body_bold_18: React.CSSProperties;
  small_body_regular_14: React.CSSProperties;
  body_regular_16: React.CSSProperties;
  large_body_regular_18: React.CSSProperties;
  body_bold_14: React.CSSProperties;
  body_caps_16: React.CSSProperties;
  tab_text_32: React.CSSProperties;
  large_body_regular_18_700: React.CSSProperties;
  heading4_bold_32: React.CSSProperties;
  heading4_semi_bold_32: React.CSSProperties;
  body_caps_semi_bold_16: React.CSSProperties;
  small_body_regular_12: React.CSSProperties;
  body_bold_18: React.CSSProperties;
  poppins_bold_20: React.CSSProperties;
  poppins_semi_bold_20: React.CSSProperties;
  poppins_regular_20: React.CSSProperties;
  small_body_12: React.CSSProperties;
  small_body_14: React.CSSProperties;
  heading3_regular_24: React.CSSProperties;
  poppins_bold_16: React.CSSProperties;
  heading4_regular_24: React.CSSProperties;
  heading1_bold_48: React.CSSProperties;
  poppins_semibold_24: React.CSSProperties;
  tab_button: React.CSSProperties;
}

const customTheme = createTheme({
  palette: palette() as PaletteOptions,
  typography: {
    ...typography,
    ...customTypography,
    button: {
      textTransform: "none",
      fontFamily: typography.fontSecondaryFamily
    },
  } as ExtendedTypographyOptions,
});

customTheme.components = overrides(customTheme);

export default customTheme;
