import { Suspense, lazy, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { PrivateRoute, useAuth } from "./auth/useAuth";
import LoadingScreen from "../components/loadingScreen";

const Dashboard = lazy(() => import('../containers/dashboard'));
const Event = lazy(() => import('../containers/events'));
const Galleries = lazy(() => import('../containers/galleries'));
const Earnings = lazy(() => import('../containers/earnings'));
const Notifications = lazy(() => import('../containers/notifications'));
const Contacts = lazy(() => import('../containers/contacts'));
const Profile = lazy(() => import('../containers/profile'));
const SignUp = lazy(() => import('../components/signUp'));
const Invoice = lazy(() => import('../containers/invoice'));
const SignIn = lazy(() => import('../components/signIn'));

function AppRoutes() {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    callLogin();
  }, [auth.isAuthenticated]);
  const callLogin = () => {
    if (!auth.isAuthenticated) {
      if (window.location.pathname !== "/login")
        return navigate("/login", {
          state: { path: window.location.pathname },
        });
    }
  };

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        <Route path="login" element={<SignIn />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="" element={<PrivateRoute element={<Dashboard />} />} />
        <Route
          path="dashboard"
          element={<PrivateRoute element={<Dashboard />} />}
        />
        <Route path="dashboard/events" element={<Event />} />
        <Route path="dashboard/events/event-view" element={<Event />} />
        <Route path="dashboard/contacts" element={<Contacts />} />
        <Route path="dashboard/galleries" element={<Galleries />} />
        <Route path="dashboard/earnings" element={<Earnings />} />
        <Route path="dashboard/notifications" element={<Notifications />} />
        <Route path="dashboard/profile" element={<PrivateRoute element={<Profile />} />} />
        <Route path="dashboard/earnings/invoice" element={<Invoice />} />
      </Routes>
    </Suspense>
  );
}
export default AppRoutes;
