import { call, put, takeLatest, all } from "redux-saga/effects";

import { SIGN_IN } from "../../../constants/redux";
import { setAccessToken, setAuthenticated } from "../../actions/auth";
import { commonApiCall } from "../../apiCaller";
type GeneratorReturnType<T> = Generator<any, T, any>;

function* handleSignIn(action: any): GeneratorReturnType<void> {
  try {
    // Make a request to the authentication endpoint with provided credentials
    const response = yield call(commonApiCall, "/idp/login", "POST", {username: action.payload.username,
      password: action.payload.password}, {});

    if (response) {
      // If authentication is successful, extract and store the bearer token in local storage
      localStorage.setItem("accessToken", response.AuthenticationResult.AccessToken);
      localStorage.setItem("profile", '1000');
      
      yield put(setAccessToken(response.AuthenticationResult.AccessToken));
      yield put(setAuthenticated(true));
      const res = response;

      delete res.accessToken;

    } else {
      // Handle authentication error
      console.error("Authentication failed");
    }
  } catch (error) {
    console.error("Error during sign-in:", error);
  }
}
export function* watchSignIn() {
  yield takeLatest(SIGN_IN, handleSignIn);
}

export function* authSaga() {
  yield all([watchSignIn()]);
}
