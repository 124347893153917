import {
  FETCH_GALLERY_REQUEST,
  FETCH_GALLERY_SUCCESS,
  FETCH_GALLERY_FAILURE,
} from "../../../constants/redux";

const initialState: any = {};

const reducers = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_GALLERY_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_GALLERY_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case FETCH_GALLERY_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return {
        ...state,
      };
  }
};

export default reducers;
