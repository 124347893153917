import {
  ADD_EQUIPMENT_FAILURE,
  ADD_EQUIPMENT_REQUEST,
  ADD_EQUIPMENT_SUCCESS,
  ADD_EXCEPTIONS_REQUEST,
  ADD_LOCATION_FAILURE,
  ADD_LOCATION_REQUEST,
  ADD_LOCATION_SUCCESS,
  ADD_ONBOARDING_LOCATION_REQUEST,
  ADD_PASSWORD_FAILURE,
  ADD_PASSWORD_REQUEST,
  ADD_PASSWORD_SUCCESS,
  ADD_PERSONAL_DETAILS_FAILURE,
  ADD_PERSONAL_DETAILS_REQUEST,
  ADD_PERSONAL_DETAILS_SUCCESS,
  ADD_SCHEDULE_FAILURE,
  ADD_SCHEDULE_REQUEST,
  ADD_SCHEDULE_SUCCESS,
  ADD_SHOOT_TYPE_API_REQUEST,
  ADD_SHOOT_TYPE_FAILURE,
  ADD_SHOOT_TYPE_REQUEST,
  ADD_SHOOT_TYPE_SUCCESS,
  ADD_WORK_PREFERENCE_FAILURE,
  ADD_WORK_PREFERENCE_REQUEST,
  ADD_WORK_PREFERENCE_SUCCESS,
  FETCH_DEFAULT_LOCATIONS_REQUEST,
  FETCH_DEFAULT_LOCATIONS_SUCCESS,
  FETCH_EQUIPMENT_REQUEST,
  FETCH_EQUIPMENT_SUCCESS,
  HANDLE_SELECTED_LOCATION_REQUEST,
  MOBILE_NUMBER_VERIFICATION_FAILURE,
  MOBILE_NUMBER_VERIFICATION_REQUEST,
  MOBILE_NUMBER_VERIFICATION_SUCCESS,
  SET_PROFILE,
  SET_PROFILE_FAILURE,
  SET_PROFILE_SUCCESS,
  UPLOAD_PROFILE_PICTURE_FAILURE,
  UPLOAD_PROFILE_PICTURE_REQUEST,
  UPLOAD_PROFILE_PICTURE_SUCCESS,
} from "../../../constants/redux";
import { PersonalDetails } from "../../../containers/types";

export const fetchProfileRequest = (params?: Record<string, any>) => ({
  type: SET_PROFILE,
  payload: { params },
});

export const fetchContactSuccess = (data: any) => ({
  type: SET_PROFILE_SUCCESS,
  payload: data,
});

export const fetchContactFailure = (error: string) => ({
  type: SET_PROFILE_FAILURE,
  payload: error,
});

//Profile - Personal Details
export const addPersonalDetailsRequest = (
  personalDetails: PersonalDetails,
  isInitial?: boolean
) => ({
  type: ADD_PERSONAL_DETAILS_REQUEST,
  payload: {personalDetails, isInitial},
});

export const addPersonalDetailsSuccess = (data: any) => ({
  type: ADD_PERSONAL_DETAILS_SUCCESS,
  payload: data,
});

export const addPersonalDetailsFailure = (error: string) => ({
  type: ADD_PERSONAL_DETAILS_FAILURE,
  payload: error,
});

/****************************Add Profile Pic***********************************/

export const uploadProfilePictureRequest = (payload: string) => ({
  type: UPLOAD_PROFILE_PICTURE_REQUEST,
  payload,
});

export const uploadProfilePictureSuccess = (data: any) => ({
  type: UPLOAD_PROFILE_PICTURE_SUCCESS,
  payload: data,
});

export const uploadProfilePictureFailure = (error: string) => ({
  type: UPLOAD_PROFILE_PICTURE_FAILURE,
  payload: error,
});


/****************************Mobile verifications***********************************/
export const mobileNumberVerificationRequest = (code: string | number) => ({
  type: MOBILE_NUMBER_VERIFICATION_REQUEST,
  payload: {code},
});

export const mobileNumberVerificationSuccess = (response: any) => ({
  type: MOBILE_NUMBER_VERIFICATION_SUCCESS,
  payload: { response },
});

export const mobileNumberVerificationFailure = (error: string) => ({
  type: MOBILE_NUMBER_VERIFICATION_FAILURE,
  payload: { error },
});


//Profile- Password change
export const addPasswordRequest = (password: string, isInitial?: boolean) => ({
  type: ADD_PASSWORD_REQUEST,
  payload: { password, isInitial },
});

export const addPasswordSuccess = (response: any) => ({
  type: ADD_PASSWORD_SUCCESS,
  payload: { response },
});

export const addPasswordFailure = (error: string) => ({
  type: ADD_PASSWORD_FAILURE,
  payload: { error },
});


//Profile - Work Preferences
export const addWorkPreferenceRequest = (workPreference: any) => ({
  type: ADD_WORK_PREFERENCE_REQUEST,
  payload: workPreference,
});

export const addWorkPreferenceSuccess = (workPreference: any) => ({
  type: ADD_WORK_PREFERENCE_SUCCESS,
  payload: workPreference,
});

export const addWorkPreferenceFailure = (error: string) => ({
  type: ADD_WORK_PREFERENCE_FAILURE,
  payload: error,
});

//Profile - Equipment
export const addEquipmentRequest = (equipment: any, isDeleted: boolean, isUpdated: boolean) => ({
  type: ADD_EQUIPMENT_REQUEST,
  payload: {equipment, isDeleted, isUpdated},
});

export const fetchEquipment = () => ({
  type: FETCH_EQUIPMENT_REQUEST,
});

export const addEquipmentSuccess = (equipment: any) => ({
  type: ADD_EQUIPMENT_SUCCESS,
  payload: equipment,
});

export const fetchEquipmentSuccess = (equipment: any) => ({
  type: FETCH_EQUIPMENT_SUCCESS,
  payload: equipment,
});

export const addEquipmentFailure = (error: string) => ({
  type: ADD_EQUIPMENT_FAILURE,
  payload: error,
});

//Profile - Schedule & Exceptions
export const addScheduleRequest = (schedule: any) => ({
  type: ADD_SCHEDULE_REQUEST,
  payload: schedule,
});

export const addExceptionsRequest = (exceptions: any) => ({
  type: ADD_EXCEPTIONS_REQUEST,
  payload: exceptions,
});

export const addScheduleSuccess = (schedule: any) => ({
  type: ADD_SCHEDULE_SUCCESS,
  payload: schedule,
});

export const addScheduleFailure = (error: string) => ({
  type: ADD_SCHEDULE_FAILURE,
  payload: error,
});

//Profile - Location and shoot types
export const addLocationRequest = (location: any) => ({
  type: ADD_LOCATION_REQUEST,
  payload: location,
});

export const addOnboardingLocationRequest = (location: any, isInitial?: boolean) => ({
  type: ADD_ONBOARDING_LOCATION_REQUEST,
  payload: {location, isInitial},
});

export const fetchDefaultLocationsRequest = () => ({
  type: FETCH_DEFAULT_LOCATIONS_REQUEST
});

export const fetchDefaultLocationsSuccess = (location: any) => ({
  type: FETCH_DEFAULT_LOCATIONS_SUCCESS,
  payload: location,
});

export const addLocationSuccess = (location: any) => ({
  type: ADD_LOCATION_SUCCESS,
  payload: location,
});

export const addLocationFailure = (error: string) => ({
  type: ADD_LOCATION_FAILURE,
  payload: error,
});

export const handleSelectedLocationRequest = (location: any) => ({
  type: HANDLE_SELECTED_LOCATION_REQUEST,
  payload: location,
});

/***************************Shoot Type*********************/

export const addShootTypesRequest = (shootType: any) => ({
  type: ADD_SHOOT_TYPE_REQUEST,
  payload: shootType,
});


export const addShootTypesApiRequest = (shootType: any) => ({
  type: ADD_SHOOT_TYPE_API_REQUEST,
  payload: shootType,
});

export const addShootTypesSuccess = (shootType: any) => ({
  type: ADD_SHOOT_TYPE_SUCCESS,
  payload: shootType,
});

export const addShootTypesFailure = (error: string) => ({
  type: ADD_SHOOT_TYPE_FAILURE,
  payload: error,
});

