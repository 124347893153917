import { SET_ACCESS_TOKEN, SET_AUTHENTICATED } from "../../../constants/redux";


interface AuthState {
  isAuthenticated: boolean;
  accessToken: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  accessToken: null,
};


const authReducer = (state = initialState, action: any): AuthState => {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };

    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
