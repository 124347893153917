const initialState = {
  rightSidebarDashboardVisible: false,
  rightSidebarEventVisible: false,
};

const sidebarReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SHOW_DASHBOARD_RIGHT_SIDEBAR":
      return {
        ...state,
        rightSidebarDashboardVisible: true,
        rightSidebarEventVisible: false,
      };

    case "SHOW_EVENT_RIGHT_SIDEBAR":
      return {
        ...state,
        rightSidebarDashboardVisible: false,
        rightSidebarEventVisible: true,
      };

    case "HIDE_RIGHT_SIDEBAR":
      return {
        ...state,
        rightSidebarDashboardVisible: false,
        rightSidebarEventVisible: false,
      };

    default:
      return state;
  }
};

export default sidebarReducer;
