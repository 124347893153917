import { Box, Card, Drawer, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import NotificationSideBarCard from "./notificationCard/notificationSideBarCard";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
//import UploadCard from "./evntCard/uploadCard";
import { lazy, useEffect, Suspense, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { fetchNotificationRequest } from "../store/actions/notifications";
import { fetchGalleryRequest } from "../store/actions/galleries";
import { convertShortTimestamp } from "../utils/dateUtils";
import { useNavigate } from "react-router-dom";
import { fetchEventRequest } from "../store/actions/events";
import { eventsTypes } from "../constants/event";
import LoadingScreen from "./loadingScreen";

const EventCard = lazy(() => import('./evntCard'));
const UploadCard = lazy(() => import('./evntCard/uploadCard'));

const drawerWidth2 = 402;
const lapScreen = 1537;

function RightBar(props: any) {
  const theme = useTheme();
  const [notificationList, setNotificationList] = useState<any[]>([]);
  const [galleriesList, setGalleriesList] = useState<any[]>([]);
  const [eventList, setEventList] = useState<any[]>([]);
  const skeletonArray: number[] = Array.from(
    { length: 4 },
    (_, index) => index + 1
  );
  const addMargin = useMediaQuery(theme.breakpoints.between("lg", lapScreen))

  useEffect(() => {
    props.rightDashboardSidebar && props.fetchNotification();
    props.rightDashboardSidebar && props.fetchGallery();
    props.rightEventSidebar && props.fetchEvent();
  }, []);

  useEffect(() => {
    const {
      notification,
      gallery,
      event,
      rightDashboardSidebar,
      rightEventSidebar,
    } = props;
    rightDashboardSidebar && notification && setNotificationList(notification);
    rightDashboardSidebar && gallery && setGalleriesList(gallery);
    rightEventSidebar && event && setEventList(event);
  }, [props.notification, props.gallery, props.event, props]);

  const navigate = useNavigate();

  const isLoading = useSelector((state: any) => {
    return state.notification.loading;
  });

  const isLoadingGallery = useSelector((state: any) => {
    return state.gallery.loading;
  });

  return (
    <Drawer
      sx={{
        width: drawerWidth2,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth2,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
      anchor="right"
    >
      {props.rightDashboardSidebar && (
        <>
          <Box sx={{ padding: "40px 16px" }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: "30px", mt: addMargin ? "44px" : "0px" }}>
              <FiberManualRecordRoundedIcon
                sx={{ color: "primary.main", mr: "4px" }}
              />
              <Typography gutterBottom variant="h4" component="div" mb="0px">
                Notifications
              </Typography>
              <Typography
                variant="body_caps_semi_bold_16"
                color={"#B6B6B6"}
                sx={{ marginRight: 2, marginLeft: "auto", cursor: "pointer" }}
                onClick={() => navigate("/dashboard/notifications")}
              >
                Go to Notifications
              </Typography>
            </Box>
            {!isLoading
              ? notificationList &&
              notificationList
                .slice(0, Math.min(notificationList.length, 5))
                .map((i, index) => (
                  <Box mb="15px" key={index}>
                    <NotificationSideBarCard {...i} />
                  </Box>
                ))
              : skeletonArray &&
              skeletonArray.map((data, index) => (
                <Box mb="15px" key={index}>
                  <Card
                    sx={{
                      maxWidth: 345,
                      borderRadius: "12px",
                      boxShadow: "2px 4px 9px 0px #00000012",
                    }}
                  >
                    <Box sx={{ padding: "16px 24px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: "8px",
                        }}
                        gap={1}
                      >
                        <Typography
                          variant="subtitle1"
                          component="div"
                          sx={{ flex: "1 1 auto", lineHeight: "1.25" }}
                        >
                          <Skeleton width={180} />
                        </Typography>
                        <Typography
                          variant="small_body_regular_12"
                          color={"#B7B7B7"}
                          sx={{ marginLeft: "auto", flex: "0 0 auto" }}
                        >
                          <Skeleton width={65} />
                        </Typography>
                      </Box>

                      <Typography variant="body1" color="grey.200">
                        <Skeleton width="100%" />
                        <Skeleton width="100%" />
                      </Typography>
                    </Box>
                  </Card>
                </Box>
              ))}
          </Box>
          <Box padding="0px 10px">
            <Divider />
          </Box>
          <Box sx={{ padding: "40px 16px" }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: "30px" }}>
              <Typography gutterBottom variant="h4" component="div" mb="0px">
                Pending Uploads
              </Typography>
              <Typography
                variant="body_caps_semi_bold_16"
                color={"#B6B6B6"}
                sx={{ marginRight: 2, marginLeft: "auto", cursor: "pointer" }}
                onClick={() => navigate("/dashboard/galleries")}
              >
                View gallery
              </Typography>
            </Box>
            <Box
              sx={{
                background: "#F8F8F8",
                p: "24px 16px",
                borderRadius: "4px",
                boxShadow: "2px 4px 9px 0px #00000012",
              }}
            >
              {!isLoadingGallery
                ? galleriesList &&
                galleriesList
                  .slice(0, Math.min(galleriesList.length, 1))
                  .map((item, index) => {
                    return (
                      <Box mb="15px">
                        {index === 0 && (
                          <Box
                            sx={{
                              display: "flex",
                              padding: "0px 10px 8px 10px",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              gutterBottom
                              variant="large_body_bold_18"
                              component="div"
                              mb="0"
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              variant="small_body_regular_14"
                              color="secondary"
                              sx={{ marginRight: 2, marginLeft: "auto" }}
                            >
                              {convertShortTimestamp(
                                item.eventStartDate,
                                true
                              )}
                            </Typography>
                          </Box>
                        )}
                        <Suspense fallback={<LoadingScreen />}>
                          {item &&
                            item.sessionCustomers &&
                            item.sessionCustomers.map((customer: any) => {
                              return (
                                <Box mb="15px">
                                  <UploadCard props={{ ...customer, ...item }} />
                                </Box>
                              );
                            })}
                        </Suspense>
                      </Box>
                    );
                  })
                : skeletonArray &&
                skeletonArray.map((data, index) => (
                  <Box mb="15px" key={index}>
                    {index === 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          padding: "0px 10px 8px 10px",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="large_body_bold_18"
                          component="div"
                          mb="0"
                        >
                          <Skeleton width={180} />
                        </Typography>
                        <Typography
                          variant="small_body_regular_14"
                          color="secondary"
                          sx={{ marginRight: 2, marginLeft: "auto" }}
                        >
                          <Skeleton width={60} />
                        </Typography>
                      </Box>
                    )}
                    <Card
                      sx={{
                        maxWidth: 345,
                        borderRadius: "12px",
                        gap: "8px",
                        boxShadow: "2px 4px 9px 0px #00000012",
                      }}
                    >
                      <Box sx={{ padding: "16px 24px" }}>
                        <Box sx={{ display: "flex", mb: "8px" }}>
                          <Typography
                            variant="small_body_regular_12"
                            component="div"
                            color="grey.300"
                          >
                            <Skeleton width={180} />
                          </Typography>
                          <Typography
                            variant="small_body_regular_12"
                            color="grey.300"
                            sx={{ marginRight: 2, marginLeft: "auto" }}
                          >
                            <Skeleton width={60} />
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography variant="h5" component="div">
                            <Skeleton width={180} />
                          </Typography>
                          <Skeleton
                            variant="rounded"
                            width={68}
                            height={32}
                            sx={{
                              p: "8px 24px",
                              marginRight: 2,
                              marginLeft: "auto",
                            }}
                          />
                        </Box>
                      </Box>
                    </Card>
                  </Box>
                ))}
            </Box>
          </Box>
        </>
      )}
      {props.rightEventSidebar && (
        <Box padding={"19.5% 0 0"}>
          <Typography
            variant="h3"
            sx={{ marginLeft: "8%", marginRight: "15%", marginBottom: "10%" }}
          >
            Assigned Events
          </Typography>
          <Box sx={{ background: "#F8F8F8", padding: "6.6% 30px" }}>
            <Suspense fallback={<LoadingScreen />}>
              {props.event &&
                props.event.map((item: any, index: any) => (
                  <>
                    <EventCard
                      isEventCard={true}
                      isAssignedSession={item.status !== eventsTypes.assigned}
                      eventData={item}
                    />
                    {index !== props.event.length - 1 && (
                      <Divider variant="middle" sx={{ marginLeft: 0 }} />
                    )}
                  </>
                ))}
            </Suspense>
          </Box>
        </Box>
      )}
    </Drawer>
  );
}

const mapStateToProps = (state: {
  event: any;
  gallery: any;
  notification: { data: [] };
}) => ({
  notification: state.notification.data,
  gallery: state.gallery.data,
  event: state.event.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchNotification: fetchNotificationRequest,
      fetchGallery: fetchGalleryRequest,
      fetchEvent: fetchEventRequest,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RightBar);
