import {
  ENABLE_RIGHT_BAR,
  FETCH_EVENT_REQUEST,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENT_FAILURE,
  CONFIRM_AVAILABILITY_REQUEST,
  CONFIRM_AVAILABILITY_SUCCESS,
  CONFIRM_AVAILABILITY_FAILURE,
  DECLINE_SESSION_REQUEST,
  DECLINE_SESSION_SUCCESS,
  DECLINE_SESSION_FAILURE,
  FETCH_EVENT_BY_ID_REQUEST,
  FETCH_EVENT_BY_ID_SUCCESS,
  FETCH_EVENT_BY_ID_FAILURE,
  FETCH_COMPLETED_EVENT_REQUEST,
  FETCH_COMPLETED_EVENT_SUCCESS,
  FETCH_SESSION_DETAILS_REQUEST,
  FETCH_SESSION_DETAILS_SUCCESS,
  FETCH_SESSION_DETAILS_FAILURE,
  ADD_IMAGE_DETAILS_REQUEST,
  ADD_IMAGE_DETAILS_SUCCESS,
  ADD_IMAGE_DETAILS_FAILURE,
  SAVE_SURVEY_FAILURE,
  SAVE_SURVEY_REQUEST,
  SAVE_SURVEY_SUCCESS,
  DELETE_SELECTED_IMAGES_FAILURE,
  DELETE_SELECTED_IMAGES_REQUEST,
  DELETE_SELECTED_IMAGES_SUCCESS,
  FETCH_IMAGES_FAILURE,
  FETCH_IMAGES_REQUEST,
  FETCH_IMAGES_SUCCESS,
  FETCH_INVITED_EVENTS_REQUEST,
  ACCEPT_EVENT_FAILURE,
  ACCEPT_EVENT_REQUEST,
  ACCEPT_EVENT_SUCCESS,
  DECLINE_EVENT_FAILURE,
  DECLINE_EVENT_REQUEST,
  DECLINE_EVENT_SUCCESS,
  FETCH_INVITED_EVENTS_FAILURE,
  FETCH_INVITED_EVENTS_SUCCESS,
  FETCH_EVENT_BY_ID_UNDO
} from "../../../constants/redux";
import {
  EnableRightBarSuccess,
  EnableRightBarSuccessPayload,
} from "../../types/events";

export const enableRightBarSuccess = (
  payload: EnableRightBarSuccessPayload
): EnableRightBarSuccess => ({
  type: ENABLE_RIGHT_BAR,
  payload,
});

export const fetchEventRequest = (params?: Record<string, any>) => ({
  type: FETCH_EVENT_REQUEST,
  payload: { params },
});
export const fetchScheduleEventRequest = (params?: Record<string, any>) => ({
  type: FETCH_COMPLETED_EVENT_REQUEST,
  payload: { params },
});

export const fetchEventSuccess = (data: any) => ({
  type: FETCH_EVENT_SUCCESS,
  payload: [...data],
});

export const fetchCompletedEventSuccess = (data: any) => ({ 
  type: FETCH_COMPLETED_EVENT_SUCCESS,
  payload: data,
});

export const fetchEventFailure = (error: string) => ({
  type: FETCH_EVENT_FAILURE,
  payload: error,
});

export const confirmAvailabilityRequest = (data: Array<Record<string, any>>) => ({
  type: CONFIRM_AVAILABILITY_REQUEST,
  payload: { data },
});

export const confirmAvailabilitySuccess = () => ({
  type: CONFIRM_AVAILABILITY_SUCCESS,
  payload: null,
});

export const confirmAvailabilityFailure = (error: string) => ({
  type: CONFIRM_AVAILABILITY_FAILURE,
  payload: error,
});


export const declineSessionRequest = (data: any) => ({
  type: DECLINE_SESSION_REQUEST,
  payload: data,
});

export const declineSessionSuccess = (data: any) => ({
  type: DECLINE_SESSION_SUCCESS,
  payload: data,
});

export const declineSessionFailure = (error: string) => ({
  type: DECLINE_SESSION_FAILURE,
  payload: error,
});

export const fetchEventByIdRequest = (params: Record<string, any>): { type: string, payload: { params: Record<string, any> } } => ({
  type: FETCH_EVENT_BY_ID_REQUEST,
  payload: { params },
});

export const fetchEventByIdSuccess = (data: any): { type: string, payload: any } => ({
  type: FETCH_EVENT_BY_ID_SUCCESS,
  payload: data,
});

export const fetchEventByIdFailure = (error: string): { type: string, payload: string } => ({
  type: FETCH_EVENT_BY_ID_FAILURE,
  payload: error,
});

export const fetchEventByIdUndo =()=> ({
  type: FETCH_EVENT_BY_ID_UNDO,
});

//Sessions
export const fetchSessionDetailsRequest = () => ({
  type: FETCH_SESSION_DETAILS_REQUEST,
});

export const fetchSessionDetailsSuccess = (payload: any) => ({
  type: FETCH_SESSION_DETAILS_SUCCESS,
  payload,
});

export const fetchSessionDetailsFailure = (error: string) => ({
  type: FETCH_SESSION_DETAILS_FAILURE,
  error,
});

// Images
export const addImageDetailsRequest = (payload: any) => ({
  type: ADD_IMAGE_DETAILS_REQUEST,
  payload,
});

export const addImageDetailsSuccess = () => ({
  type: ADD_IMAGE_DETAILS_SUCCESS,
});

export const addImageDetailsFailure = (error: any) => ({
  type: ADD_IMAGE_DETAILS_FAILURE,
  error,
});

export const deleteSelectedImagesRequest = (imageIds: any[]) => ({
  type: DELETE_SELECTED_IMAGES_REQUEST,
  payload: imageIds,
});

export const deleteSelectedImagesSuccess = (imageIds: any[]) => ({
  type: DELETE_SELECTED_IMAGES_SUCCESS,
  payload: imageIds,
});

export const deleteSelectedImagesFailure = (error: string)=> ({
  type: DELETE_SELECTED_IMAGES_FAILURE,
  error,
});

export const fetchImagesRequest = () => ({
  type: FETCH_IMAGES_REQUEST,
});

export const fetchImagesSuccess = (payload: any) => ({
  type: FETCH_IMAGES_SUCCESS,
  payload,
});

export const fetchImagesFailure = (error: string) => ({
  type: FETCH_IMAGES_FAILURE,
  error,
});

// save survey 
export const saveSurveyRequest = (payload: any) => ({
  type: SAVE_SURVEY_REQUEST,
  payload,
});

export const saveSurveySuccess = () => ({
  type: SAVE_SURVEY_SUCCESS,
});

export const saveSurveyFailure = (error: string) => ({
  type: SAVE_SURVEY_FAILURE,
  error,
});


// Invited events
// Fetch Invited Events
export const fetchInvitedEventsRequest = () => ({
  type: FETCH_INVITED_EVENTS_REQUEST,
});

export const fetchInvitedEventsSuccess = (data: any) => ({
  type: FETCH_INVITED_EVENTS_SUCCESS,
  payload: [...data],
});

export const fetchInvitedEventsFailure = (error: string) => ({
  type: FETCH_INVITED_EVENTS_FAILURE,
  payload: error,
});

// Accept Event
export const acceptEventRequest = (eventId: string) => ({
  type: ACCEPT_EVENT_REQUEST,
  payload: eventId,
});

export const acceptEventSuccess = (eventId: string) => ({
  type: ACCEPT_EVENT_SUCCESS,
  payload: eventId,
});

export const acceptEventFailure = (error: string) => ({
  type: ACCEPT_EVENT_FAILURE,
  payload: error,
});

// Decline Event
export const declineEventRequest = (eventId: string) => ({
  type: DECLINE_EVENT_REQUEST,
  payload: eventId,
});

export const declineEventSuccess = (eventId: string) => ({
  type: DECLINE_EVENT_SUCCESS,
  payload: eventId,
});

export const declineEventFailure = (error: string) => ({
  type: DECLINE_EVENT_FAILURE,
  payload: error,
});