export const eventTypes = {
    available: "available",
    reserved: "reserved",
    pending: "pending",
    confirmed: "confirmed",
    completed: "completed",
    declined: "declined",
    assigned: "assigned"
  };

  //not use
/**
 *  assigned: Red Color
    invited:  Green Color- Light
    completed: Green Color- Dark
    backup: Red,and cross lines 
    available: White Color
 */
  export const eventsTypes = { 
    assigned: "assigned",
    invited: "invited",
    completed: "completed",
    backup: "backup",
    available: "available",
  };
  //not use
  export const sessionTypes = {
    confirmed: "confirmed",
    available: "available",
    completed: "completed",
    pending: "pending",
  };
  //reference to pending in sessionTypes
  export const pendingTypes = {
    accept:'accept',
    decline: 'decline'
  }
