import {
    SHOW_ALERT,
    HIDE_ALERT
  } from "../../../constants/redux";
  
  const initialState: any = {};
  
  const reducers = (state = initialState, action: any) => {
    switch (action.type) {
      case SHOW_ALERT:
        return { ...state, loading: false, data: action.payload };
      case HIDE_ALERT:
        return { ...state, loading: false, data: action.payload };
  
      default:
        return {
          ...state,
        };
    }
  };
  
  export default reducers;