export const primaryFont = "Playfair Display";
export const secondaryFont = "Poppins";

interface responsiveBreakPoints {
  sm: number;
  md: number;
  lg: number;
}

export function responsiveFontSizes({ sm, md, lg }: responsiveBreakPoints) {
  return {
    "@media (max-width:600px)": {
      fontSize: `${sm}px`,
    },
    "@media (min-width:600px) and (max-width: 900px)": {
      fontSize: `${md}px`,
    },
    "@media (min-width:900px)": {
      fontSize: `${lg}px`,
    },
  };
}

export const typography = {
  fontFamily: primaryFont,
  fontSecondaryFamily: secondaryFont,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  h1: {
    fontFamily: primaryFont,
    fontSize: "48px",
    fontWeight: 600,
    ...responsiveFontSizes({ sm: 24, md: 38, lg: 48 }),
  },
  h2: {
    fontFamily: secondaryFont,
    fontSize: "36px",
    fontWeight: 600,
    ...responsiveFontSizes({ sm: 18, md: 30, lg: 36 }),
  },
  h3: {
    fontFamily: secondaryFont,
    fontSize: "32px",
    fontWeight: 700,
    ...responsiveFontSizes({ sm: 20, md: 26, lg: 32 }),
  },
  h4: {
    fontFamily: secondaryFont,
    fontSize: "24px",
    fontWeight: 600,
    ...responsiveFontSizes({ sm: 16, md: 20, lg: 24 }),
  },
  h5: {
    fontFamily: secondaryFont,
    fontSize: "18px",
    fontWeight: 500,
    ...responsiveFontSizes({ sm: 14, md: 16, lg: 18 }),
  },
  subtitle1: {
    fontFamily: secondaryFont,
    fontWeight: 600,
    fontSize: "16px",
    ...responsiveFontSizes({ sm: 12, md: 14, lg: 16 }),
  },
  subtitle2: {
    fontFamily: secondaryFont,
    fontWeight: 600,
    fontSize: "14px",
    ...responsiveFontSizes({ sm: 10, md: 12, lg: 14 }),
  },
  body1: {
    fontFamily: secondaryFont,
    fontWeight: 400,
    fontSize: "14px",
    ...responsiveFontSizes({ sm: 10, md: 12, lg: 14 }),
  },
  body2: {
    fontFamily: secondaryFont,
    fontWeight: 600,
    fontSize: "14px",
    ...responsiveFontSizes({ sm: 10, md: 12, lg: 14 }),
  },
  caption: {
    fontFamily: secondaryFont,
    fontWeight: 400,
    fontSize: "12px",
    ...responsiveFontSizes({ sm: 8, md: 10, lg: 12 }),
  },
  button: {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: secondaryFont,
  },
};

export const customTypography = {
  large_body_bold_18: {
    fontFamily: secondaryFont,
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "150%",
    ...responsiveFontSizes({ sm: 14, md: 16, lg: 18 }),
  },
  large_body_regular_18: {
    fontFamily: secondaryFont,
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "150%",
    ...responsiveFontSizes({ sm: 14, md: 16, lg: 18 }),
  },
  body_regular_16: {
    fontFamily: secondaryFont,
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "150%",
    ...responsiveFontSizes({ sm: 12, md: 14, lg: 16 }),
  },
  small_body_regular_14: {
    fontFamily: secondaryFont,
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "150%",
    ...responsiveFontSizes({ sm: 10, md: 12, lg: 14 }),
  },
  body_bold_14: {
    fontFamily: secondaryFont,
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "150%",
    ...responsiveFontSizes({ sm: 14, md: 16, lg: 18 }),
  },
  large_body_regular_18_700: {
    fontFamily: secondaryFont,
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "150%",
    ...responsiveFontSizes({ sm: 14, md: 16, lg: 18 }),
  },
  body_caps_16: {
    fontFamily: secondaryFont,
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "150%",
    ...responsiveFontSizes({ sm: 12, md: 14, lg: 16 }),
  },
  tab_text_32: {
    fontFamily: secondaryFont,
    fontSize: "32px",
    fontWeight: 400,
    lineHeight: "150%",
    ...responsiveFontSizes({ sm: 16, md: 26, lg: 32 }),
  },
  heading4_bold_32: {
    fontFamily: primaryFont,
    fontSize: "32px",
    fontWeight: 400,
    lineHeight: "150%",
    ...responsiveFontSizes({ sm: 20, md: 26, lg: 32 }),
  },
  heading4_semi_bold_32: {
    fontFamily: secondaryFont,
    fontSize: "32px",
    fontWeight: 600,
    lineHeight: "150%",
    ...responsiveFontSizes({ sm: 20, md: 26, lg: 32 }),
  },
  body_caps_semi_bold_16: {
    fontFamily: secondaryFont,
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "150%",
    ...responsiveFontSizes({ sm: 12, md: 14, lg: 16 }),
  },
  small_body_regular_12: {
    fontFamily: secondaryFont,
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "150%",
    ...responsiveFontSizes({ sm: 8, md: 10, lg: 12 }),
  },
  body_bold_18: {
    fontFamily: secondaryFont,
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "150%",
    ...responsiveFontSizes({ sm: 14, md: 16, lg: 18 }),
  },
  poppins_bold_20: {
    fontFamily: secondaryFont,
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "160%",
    ...responsiveFontSizes({ sm: 15, md: 18, lg: 20 }),
  },
  poppins_semi_bold_20: {
    fontFamily: secondaryFont,
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "160%",
    ...responsiveFontSizes({ sm: 15, md: 18, lg: 20 }),
  },
  poppins_regular_20: {
    fontFamily: secondaryFont,
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "160%",
    ...responsiveFontSizes({ sm: 15, md: 18, lg: 20 }),
  },
  small_body_12: {
    fontFamily: secondaryFont,
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "130%",
    ...responsiveFontSizes({ sm: 8, md: 10, lg: 12 }),
  },
  small_body_14: {
    fontFamily: secondaryFont,
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "150%",
    ...responsiveFontSizes({ sm: 10, md: 12, lg: 14 }),
  },
  heading3_regular_24: {
    fontFamily: secondaryFont,
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "150%",
    ...responsiveFontSizes({ sm: 16, md: 20, lg: 24 }),
  },
  poppins_bold_16: {
    fontFamily: secondaryFont,
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "150%",
    ...responsiveFontSizes({ sm: 12, md: 14, lg: 16 }),
  },
  heading4_regular_24: {
    fontFamily: secondaryFont,
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "150%",
    ...responsiveFontSizes({ sm: 16, md: 20, lg: 24 }),
  },
  heading1_bold_48: {
    fontFamily: secondaryFont,
    fontSize: "48px",
    fontWeight: 700,
    lineHeight: "150%",
    ...responsiveFontSizes({ sm: 32, md: 38, lg: 48 }),
  },
  poppins_semibold_24: {
    fontFamily: secondaryFont,
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "150%",
    ...responsiveFontSizes({ sm: 16, md: 20, lg: 24 }),
  },
  tab_button: {
    fontFamily: primaryFont,
    fontSize: "40px",
    fontWeight: 500,
    lineHeight: "150%",
    ...responsiveFontSizes({ sm: 24, md: 40, lg: 40 }),
  },
};
