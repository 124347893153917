// dataSaga.ts
import {
  call,
  put,
  takeEvery,
  all,
  takeLatest,
  select,
} from "redux-saga/effects";
import {
  fetchEventSuccess,
  fetchEventFailure,
  confirmAvailabilitySuccess,
  confirmAvailabilityFailure,
  declineSessionSuccess,
  declineSessionFailure,
  fetchEventByIdSuccess,
  fetchEventByIdFailure,
  fetchCompletedEventSuccess,
  fetchSessionDetailsSuccess,
  fetchSessionDetailsFailure,
  addImageDetailsSuccess,
  addImageDetailsFailure,
  saveSurveySuccess,
  saveSurveyFailure,
  fetchImagesSuccess,
  fetchImagesFailure,
  deleteSelectedImagesSuccess,
  acceptEventFailure,
  acceptEventSuccess,
  declineEventFailure,
  declineEventSuccess,
  fetchInvitedEventsFailure,
  fetchInvitedEventsSuccess,
} from "../../actions/events";

import { ShowAlert } from "../../actions/alert";

import {
  FETCH_EVENT_REQUEST,
  CONFIRM_AVAILABILITY_REQUEST,
  DECLINE_SESSION_REQUEST,
  FETCH_EVENT_BY_ID_REQUEST,
  FETCH_COMPLETED_EVENT_REQUEST,
  FETCH_SESSION_DETAILS_REQUEST,
  ADD_IMAGE_DETAILS_REQUEST,
  SAVE_SURVEY_REQUEST,
  DELETE_SELECTED_IMAGES_REQUEST,
  FETCH_IMAGES_REQUEST,
  ACCEPT_EVENT_REQUEST,
  DECLINE_EVENT_REQUEST,
  FETCH_INVITED_EVENTS_REQUEST,
} from "../../../constants/redux";

import { commonApiCall } from "../../apiCaller";
import { getUserIdFromState } from "../../authUtils";
import { ACCEPT_SUCCESSFULL_MESSAGE, COMMON_ERROR_MESSAGE, COMMON_SAVE_ERROR_MESSAGE, COMMON_SUCCESSFULL_MESSAGE, DECLINE_SUCCESSFULL_MESSAGE, EVENT_CONFIRMED_MESSAGE, EVENT_DECLINED_MESSAGE } from "../../../constants/redux/messages";

type GeneratorReturnType<T> = Generator<any, T, any>;

const eventUrl = "";
const invitedUrl = "invited";
const dashboardUrl = "";
const eventPath = "events";
const imageGalleryPath = "galleries/image";

function* fetchScheduleDataSaga(action: any): GeneratorReturnType<void> {
  try {
    const response = yield call(
      commonApiCall,
      `${eventUrl}/${eventPath}`,
      "GET",
      {},
      {}
    );

    yield put(fetchEventSuccess(response));
  } catch (error: any) {
    yield put(fetchEventFailure(error.message));
    yield put(
      ShowAlert({
        showAlert: true,
        message: error.message || COMMON_ERROR_MESSAGE,
        type: "error",
      })
    );
  }
}

function* fetchCompletedDataSaga(action: any): GeneratorReturnType<void> {
  try {
    const response = yield call(
      commonApiCall,
      `${eventUrl}/${eventPath}?completed=true`,
      "GET",
      {},
      {}
    );

    yield put(fetchCompletedEventSuccess(response));
  } catch (error: any) {
    yield put(fetchEventFailure(error.message));
    yield put(
      ShowAlert({
        showAlert: true,
        message: error.message || COMMON_ERROR_MESSAGE,
        type: "error",
      })
    );
  }
}

function* confirmAvailabilitySaga(action: any): GeneratorReturnType<void> {
  try {

    const response = yield call(
      commonApiCall,
      `${dashboardUrl}/${eventPath}/confirm-availability`, // Replace with your actual API endpoint for confirming availability
      "POST",
      action.payload.data,
      {}
    );

    yield put(confirmAvailabilitySuccess());
    yield put(
      ShowAlert({
        showAlert: true,
        message: response.message || EVENT_CONFIRMED_MESSAGE,
        type: "success",
      })
    );
  } catch (error: any) {
    yield put(confirmAvailabilityFailure(error.message));
    yield put(
      ShowAlert({
        showAlert: true,
        message: error.message || COMMON_SAVE_ERROR_MESSAGE,
        type: "error",
      })
    );
  }
}

function* declineSessionSaga(action: any): GeneratorReturnType<void> {
  try {
    const response = yield call(
      commonApiCall,
      `${dashboardUrl}/${eventPath}/decline`, // Replace with your actual API endpoint for confirming availability
      "DELETE",
      { session_ids: action.payload },
      {}
    );
    yield put(declineSessionSuccess(response));
    yield put(
      ShowAlert({
        showAlert: true,
        message: response.massage || EVENT_DECLINED_MESSAGE,
        type: "success",
      })
    );
  } catch (error: any) {
    yield put(declineSessionFailure(error.message));
    yield put(
      ShowAlert({
        showAlert: true,
        message: error.message || COMMON_SAVE_ERROR_MESSAGE,
        type: "error",
      })
    );
  }
}

function* fetchScheduleEventByIdSaga(action: any): GeneratorReturnType<void> {
  try {
    
    const response = yield call(
      commonApiCall,
      `${eventUrl}/${eventPath}/${action.payload.params}`,
      "GET",
      {},
      {}
    );

    yield put(fetchEventByIdSuccess(response));
  } catch (error: any) {
    yield put(fetchEventByIdFailure(error.message));
    yield put(
      ShowAlert({
        showAlert: true,
        message: error.message || COMMON_ERROR_MESSAGE,
        type: "error",
      })
    );
  }
}

//session get by ID
function* fetchSessionDetails(action: any): GeneratorReturnType<void> {
  try {
    
    const response = yield call(
      commonApiCall,
      `${eventUrl}/session`,
      "GET",
      {},
      {}
    );
    yield put(fetchSessionDetailsSuccess(response));
  } catch (error: any) {
    yield put(fetchSessionDetailsFailure(error));
    yield put(
      ShowAlert({
        showAlert: true,
        message: error.message || COMMON_ERROR_MESSAGE,
        type: "error",
      })
    );
  }
}

function* addImageDetails(action: any): GeneratorReturnType<void> {
  try {
    yield call(
      commonApiCall,
      `${dashboardUrl}/${imageGalleryPath}`,
      "POST",
      action.payload,
      {}
    );

    yield put(addImageDetailsSuccess());
  } catch (error: any) {
    yield put(addImageDetailsFailure(error));
  }
}

function* getImageDetails(action: any): GeneratorReturnType<void> {
  try {
    
    const response = yield call(
      commonApiCall,
      `${eventUrl}/${imageGalleryPath}`,
      "GET",
      {},
      {}
    );

    yield put(fetchImagesSuccess(response));
  } catch (error: any) {
    yield put(fetchImagesFailure(error));
    yield put(
      ShowAlert({
        showAlert: true,
        message: error.message || COMMON_ERROR_MESSAGE,
        type: "error",
      })
    );
  }
}

function* deleteImageRequest(action: any): GeneratorReturnType<void> {
  try {
    
    yield call(
      commonApiCall,
      `${dashboardUrl}/${imageGalleryPath}`,
      "DELETE",
      action.payload,
      {}
    );

    yield put(deleteSelectedImagesSuccess(action.payload));
  } catch (error: any) {
    yield put(addImageDetailsFailure(error));
  }
}
// sarvey request
function* saveSurvey(action: any): GeneratorReturnType<void> {
  try {
   
    const response = yield call(
      commonApiCall,
      `${dashboardUrl}/galleries/survey`,
      "POST",
      action.payload,
      {}
    );
    yield put(saveSurveySuccess());
    yield put(
      ShowAlert({
        showAlert: true,
        message: response && response.message || COMMON_SUCCESSFULL_MESSAGE,
        type: "success",
      })
    );
  } catch (error: any) {
    yield put(saveSurveyFailure(error));
    yield put(
      ShowAlert({
        showAlert: true,
        message: error.message || COMMON_SAVE_ERROR_MESSAGE,
        type: "error",
      })
    );
  }
}

// Fetch Invited Events Saga
function* fetchInvitedEventsSaga(action: any): GeneratorReturnType<void> {
  try {
    
    const response = yield call(
      commonApiCall,
      `${eventUrl}/${invitedUrl}`,
      "GET",
      {},
      {}
    );

    yield put(fetchInvitedEventsSuccess(response));
  } catch (error: any) {
    yield put(fetchInvitedEventsFailure(error.message));
    yield put(
      ShowAlert({
        showAlert: true,
        message: error.message || COMMON_ERROR_MESSAGE,
        type: "error",
      })
    );
  }
}

// Accept Event Saga
function* acceptEventSaga(action: any): GeneratorReturnType<void> {
  try {
    
    const response = yield call(
      commonApiCall,
      `${eventUrl}/${invitedUrl}/accept`,
      "PUT",
      { "event-id": action.payload },
      {}
    );
    yield put(acceptEventSuccess(action.payload));
    yield put(
      ShowAlert({
        showAlert: true,
        message: response.message || ACCEPT_SUCCESSFULL_MESSAGE,
        type: "success",
      })
    );
  } catch (error: any) {
    yield put(acceptEventFailure(error.message));
    yield put(
      ShowAlert({
        showAlert: true,
        message: error.message || COMMON_SAVE_ERROR_MESSAGE,
        type: "error",
      })
    );
  }
}

// Decline Event Saga
function* declineEventSaga(action: any): GeneratorReturnType<void> {
  try {
    
    const response = yield call(
      commonApiCall,
      `${eventUrl}/${invitedUrl}/decline`,
      "POST",
      { "event-id": action.payload },
      {}
    );

    yield put(
      ShowAlert({
        showAlert: true,
        message: response.message || DECLINE_SUCCESSFULL_MESSAGE,
        type: "success",
      })
    );
    yield put(declineEventSuccess(action.payload));
  } catch (error: any) {
    yield put(declineEventFailure(error.message));
    yield put(
      ShowAlert({
        showAlert: true,
        message: error.message || COMMON_SAVE_ERROR_MESSAGE,
        type: "error",
      })
    );
  }
}

function* watchFetchEventById() {
  yield takeLatest(FETCH_EVENT_BY_ID_REQUEST, fetchScheduleEventByIdSaga);
}

function* watchDeclineSession() {
  yield takeEvery(DECLINE_SESSION_REQUEST, declineSessionSaga);
}

function* watchConfirmAvailability() {
  yield takeEvery(CONFIRM_AVAILABILITY_REQUEST, confirmAvailabilitySaga);
}

function* watchFetchData() {
  yield takeEvery(FETCH_EVENT_REQUEST, fetchScheduleDataSaga);
}

function* watchCompletedFetchData() {
  yield takeEvery(FETCH_COMPLETED_EVENT_REQUEST, fetchCompletedDataSaga);
}

function* watchAddImageDetails() {
  yield takeEvery(ADD_IMAGE_DETAILS_REQUEST, addImageDetails);
}

export function* watchFetchImages() {
  yield takeLatest(FETCH_IMAGES_REQUEST, getImageDetails);
}

function* watchDeleteSelectedImages() {
  yield takeLatest(DELETE_SELECTED_IMAGES_REQUEST, deleteImageRequest);
}

function* watchFetchSessionDetails() {
  yield takeEvery(FETCH_SESSION_DETAILS_REQUEST, fetchSessionDetails);
}

function* watchSaveSurveyRequest() {
  yield takeEvery(SAVE_SURVEY_REQUEST, saveSurvey);
}

function* watchFetchInvitedEvents() {
  yield takeLatest(FETCH_INVITED_EVENTS_REQUEST, fetchInvitedEventsSaga);
}

function* watchAcceptEvent() {
  yield takeLatest(ACCEPT_EVENT_REQUEST, acceptEventSaga);
}

function* watchDeclineEvent() {
  yield takeLatest(DECLINE_EVENT_REQUEST, declineEventSaga);
}

export function* eventSaga() {
  yield all([
    watchFetchData(),
    watchCompletedFetchData(),
    watchConfirmAvailability(),
    watchDeclineSession(),
    watchFetchEventById(),
    watchFetchSessionDetails(),
    watchAddImageDetails(),
    watchDeleteSelectedImages(),
    watchSaveSurveyRequest(),
    watchFetchImages(),
    watchFetchInvitedEvents(),
    watchAcceptEvent(),
    watchDeclineEvent(),
  ]);
}
