// dataSaga.ts
import { call, put, all, takeLatest, select } from "redux-saga/effects";
import {
  fetchEarningsSuccess,
  fetchEarningsFailure,
  fetchInvoicesSuccess,
} from "../../actions/earnings";

import { FETCH_EARNINGS_REQUEST } from "../../../constants/redux";
import { commonApiCall } from "../../apiCaller";
import { ShowAlert } from "../../actions/alert";
import { COMMON_ERROR_MESSAGE } from "../../../constants/redux/messages";
import { getUserIdFromState } from "../../authUtils";

type GeneratorReturnType<T> = Generator<any, T, any>;
const dashboardPath = "";

function* fetchEarningsSaga(action: any): GeneratorReturnType<void> {
  try {
    const response = yield call(
      commonApiCall,
      `${dashboardPath}/earnings`,
      "GET",
      {},
      {}
    );
    yield put(fetchEarningsSuccess(response));
  } catch (error: any) {
    yield put(fetchEarningsFailure(error.message));
    yield put(
      ShowAlert({
        showAlert: true,
        message: error.message || COMMON_ERROR_MESSAGE,
        type: "error",
      })
    );
  }
}

function* fetchInvoicesSaga(action: any): GeneratorReturnType<void> {
  try {
    const response = yield call(
      commonApiCall,
      `${dashboardPath}/invoices`,
      "GET",
      {},
      {}
    );

    yield put(fetchInvoicesSuccess(response));
  } catch (error: any) {
    yield put(fetchEarningsFailure(error.message));
    yield put(
      ShowAlert({
        showAlert: true,
        message: error.message || COMMON_ERROR_MESSAGE,
        type: "error",
      })
    );
  }
}

function* watchFetchData() {
  yield takeLatest(FETCH_EARNINGS_REQUEST, fetchEarningsSaga);
}
function* watchFetchInvoiceData() {
  yield takeLatest(FETCH_EARNINGS_REQUEST, fetchInvoicesSaga);
}

export function* earningsSaga() {
  yield all([watchFetchData(), watchFetchInvoiceData()]);
}
