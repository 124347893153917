import {
  FETCH_NOTIFICATION_REQUEST,
  FETCH_NOTIFICATION_SUCCESS,
  FETCH_NOTIFICATION_FAILURE,
  DELETE_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAILURE,
  READ_NOTIFICATION_REQUEST,
  READ_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION_FAILURE,
  READ_SINGLE_NOTIFICATION_REQUEST,
  READ_SINGLE_NOTIFICATION_SUCCESS,
  READ_SINGLE_NOTIFICATION_FAILURE,
} from "../../../constants/redux";

import { Notification as INotification } from "../../../containers/types";

//Fetch  Notifications
export const fetchNotificationRequest = (params?: Record<string, any>) => ({
  type: FETCH_NOTIFICATION_REQUEST,
  payload: { params },
});

export const fetchNotificationSuccess = (data: any) => ({
  type: FETCH_NOTIFICATION_SUCCESS,
  payload: data,
});

export const fetchNotificationFailure = (error: string) => ({
  type: FETCH_NOTIFICATION_FAILURE,
  payload: error,
});

//Delete  Notifications
export const deleteNotificationRequest = (
  params?: string | INotification[]
) => ({
  type: DELETE_NOTIFICATION_REQUEST,
  payload: params,
});

export const deleteNotificationSuccess = (data: any) => ({
  type: DELETE_NOTIFICATION_SUCCESS,
  payload: data,
});

export const deleteNotificationFailure = (error: string) => ({
  type: DELETE_NOTIFICATION_FAILURE,
  payload: error,
});

//Read  Notifications
export const readNotificationRequest = (params: any) => ({
  type: READ_NOTIFICATION_REQUEST,
  payload: { params },
});

export const readNotificationSuccess = (data: any) => ({
  type: READ_NOTIFICATION_SUCCESS,
  payload: data,
});

export const readNotificationFailure = (error: string) => ({
  type: READ_NOTIFICATION_FAILURE,
  payload: error,
});
//read, unread single notification
export const readSingleNotificationRequest = (id: any, status: boolean) => ({
  type: READ_SINGLE_NOTIFICATION_REQUEST,
  payload: { id, status },
});

export const readSingleNotificationSuccess = (data: any) => ({
  type: READ_SINGLE_NOTIFICATION_SUCCESS,
  payload: data,
});

export const readSingleNotificationFailure = (error: string) => ({
  type: READ_SINGLE_NOTIFICATION_FAILURE,
  payload: error,
});
