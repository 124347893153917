import {
  FETCH_GALLERY_REQUEST,
  FETCH_GALLERY_SUCCESS,
  FETCH_GALLERY_FAILURE,
} from "../../../constants/redux";

export const fetchGalleryRequest = (params?: Record<string, any>) => ({
  type: FETCH_GALLERY_REQUEST,
  payload: { params },
});

export const fetchGallerySuccess = (data: any) => ({
  type: FETCH_GALLERY_SUCCESS,
  payload: data,
});

export const fetchGalleryFailure = (error: string) => ({
  type: FETCH_GALLERY_FAILURE,
  payload: error,
});
