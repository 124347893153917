import {
  FETCH_EARNINGS_REQUEST,
  FETCH_EARNINGS_SUCCESS,
  FETCH_EARNINGS_FAILURE,
  FETCH_INVOICES_SUCCESS,
  FETCH_INVOICES_REQUEST
} from "../../../constants/redux";

const initialState: any = {};

const reducers = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_EARNINGS_REQUEST:
    case  FETCH_INVOICES_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_EARNINGS_SUCCESS:
      return { ...state, loading: false, summary: action.payload };
    case FETCH_INVOICES_SUCCESS:
      return { ...state, loading: false, invoices: action.payload };
    case FETCH_EARNINGS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return {
        ...state,
      };
  }
};

export default reducers;
