export const profileKeys = {
  profile: {
    NAME: "name",
    EMAIL: "email",
    IMAGE_URL: "profile_picture_url",
    EVENT_COMPLETED: 'event_completed',
    TOTAL_EARNINGS: 'total_earnings',
    MOBILE_NUMBER: "mobile_number",
    PROFILE_PICTURE_URL: "profile_picture_url",
    SUBSCRIBER: "subscriber",
  },
  taggedCategories: {
    SUBSCRIBER: "subscriber",
    CATEGORY: "category",
    STATUS: 'status'
  },
  taggedLocations: {
    SUBSCRIBER: "subscriber",
    VENUE: "venue",
    LOCATION_STATUS: 'locationStatus'
  },
  schedule: {
    STATUS: "status",
    TYPE: "type",
    ITEM_ID: "itemid",
    SUBSCRIBER: "subscriber",
    START_TIME: "start_time",
    END_TIME: "end_time",
    TITLE: "title",
    EPOCH: "epoch",
    STATUS_VALUES: 'Available'
  },
  equipment: {
    STATUS: "status",
    ITEM: "item",
    SUBSCRIBER: "subscriber",
    LAST_ACTION: "last_action",
    CATEGORY: "category",
    DESCRIPTION: "description",
    TITLE: "title",
  },
};
