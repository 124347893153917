import {
  SET_ACCESS_TOKEN,
  SET_AUTHENTICATED,
  SIGN_IN,
} from "../../../constants/redux";

export const signIn = (username: string, password: string) => ({
  type: SIGN_IN,
  payload: { username, password },
});

export const setAuthenticated = (isAuthenticated: boolean) => ({
  type: SET_AUTHENTICATED,
  payload: isAuthenticated,
});

export const setAccessToken = (accessToken: string | null) => ({
  type: SET_ACCESS_TOKEN,
  payload: accessToken,
});
