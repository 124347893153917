import {
  FETCH_EARNINGS_REQUEST,
  FETCH_EARNINGS_SUCCESS,
  FETCH_EARNINGS_FAILURE,
  FETCH_INVOICES_REQUEST,
  FETCH_INVOICES_SUCCESS,
} from "../../../constants/redux";

export const fetchEarningsRequest = (params?: Record<string, any>) => ({
  type: FETCH_EARNINGS_REQUEST,
  payload: { params },
});

export const fetchEarningsSuccess = (data: any) => ({
  type: FETCH_EARNINGS_SUCCESS,
  payload: data,
});
export const fetchInvoicesRequest = (params?: Record<string, any>) => ({
  type: FETCH_INVOICES_REQUEST,
  payload: { params },
});

export const fetchInvoicesSuccess = (data: any) => ({
  type: FETCH_INVOICES_SUCCESS,
  payload: data,
});

export const fetchEarningsFailure = (error: string) => ({
  type: FETCH_EARNINGS_FAILURE,
  payload: error,
});

