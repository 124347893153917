// import "./App.css";
import { connect, useSelector } from "react-redux";
import {
  Box,
  ThemeProvider,
  Toolbar,
  IconButton,
  Badge,
  useMediaQuery,
  Zoom,
} from "@mui/material";
import AppRoutes from "./routes";
import customTheme from "../src/theme/index";

import CssBaseline from "@mui/material/CssBaseline";
import RightBar from "./components/rightBar";
import LeftBar from "./components/leftBar";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { CSSObject, Theme, styled, useTheme } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AlertComponent from "./components/alert";
import { ShowAlert, HideAlert } from "./store/actions/alert";
import { Dispatch, bindActionCreators } from "redux";
import MuiDrawer from "@mui/material/Drawer";
import { signIn } from "./store/actions/auth";

const drawerWidth = 272;
const lapScreen = 1537;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  background: theme.palette.secondary.main,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "64px",
  [theme.breakpoints.up("md")]: {
    width: "64px",
  },
  [theme.breakpoints.down("md")]: {
    width: "0px",
  },
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  //whiteSpace: 'nowrap',
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function App(props: any) {
  const location = useLocation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const currentRoute = location.pathname;
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const sideBarScreen = useMediaQuery(theme.breakpoints.down(lapScreen));
  const showRightSideBar = useMediaQuery(theme.breakpoints.up("lg"));

  const handleClick = () => {
    if (currentRoute === "/dashboard/notifications") {
      navigate(-1);
    } else {
      navigate("/dashboard/notifications");
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const showAlert = useSelector((state: any) => {
    return state.alert?.data?.showAlert;
  });

  useEffect(() => {
    signInCall();
  }, []);

  const signInCall = async () => {
    const accessToken = localStorage.getItem("accessToken");
    !(props.profile && props.profile.name) &&
      accessToken &&
      (await props.signIn());
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Box
        bgcolor={"background.default"}
        color={"text.primary"}
        sx={{ display: "flex" }}
      >
        <Zoom in={showAlert}>
          <Box
            sx={
              isExtraSmallScreen
                ? {
                    position: "fixed",
                    bottom: "5vh",
                    right: "5vh",
                    zIndex: 9999,
                    left: "5vh",
                  }
                : {
                    position: "fixed",
                    bottom: "5vh",
                    right: "5vh",
                    zIndex: 9999,
                  }
            }
          >
            <AlertComponent />
          </Box>
        </Zoom>
        {location.pathname === "/signup" || location.pathname === "/login" ? (
          <AppRoutes />
        ) : (
          <>
            <CssBaseline />

            {sideBarScreen ? (
              <Box sx={{ display: "flex", width: "100%" }}>
                <CssBaseline />
                <AppBar
                  position="fixed"
                  open={open}
                  sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                >
                  <Toolbar
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                      sx={{ mr: 2, ...(open && { display: "none" }) }}
                    >
                      <MenuIcon />
                    </IconButton>
                    <IconButton
                      size="large"
                      aria-label="show 17 new notifications"
                      color="inherit"
                      onClick={handleClick}
                    >
                      <Badge badgeContent={17} color="error">
                        <NotificationsIcon />
                      </Badge>
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                  <DrawerHeader
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  >
                    <IconButton onClick={handleDrawerClose}>
                      {theme.direction === "ltr" ? (
                        <ChevronLeftIcon />
                      ) : (
                        <ChevronRightIcon />
                      )}
                    </IconButton>
                  </DrawerHeader>
                  <LeftBar isLaptop={open} closeSideBar={handleDrawerClose} />
                </Drawer>
                <Box component="main" sx={{ p: "0px", width: "100%" }}>
                  <DrawerHeader />
                  <Box
                    sx={{
                      backgroundColor: "#EFEDEB",
                      minHeight: "100vh",
                      width: "100%",
                      paddingLeft: isExtraSmallScreen ? "15px" : 5,
                      paddingRight: isExtraSmallScreen ? "15px" : 5,
                      height: "100%",
                      paddingTop: 5,
                    }}
                  >
                    <AppRoutes />
                  </Box>
                </Box>
              </Box>
            ) : (
              <>
                <LeftBar isLaptop={true} width={drawerWidth} />
                <Box
                  sx={{
                    backgroundColor: "#EFEDEB",
                    minHeight: "100vh",
                    width: "100%",
                    paddingLeft: 5,
                    paddingRight: 5,
                    height: "100%",
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                >
                  <AppRoutes />
                </Box>
              </>
            )}

            {showRightSideBar &&
              !!(props.rightDashboardSidebar | props.rightEventSidebar) && (
                <RightBar {...props} />
              )}
          </>
        )}
      </Box>
    </ThemeProvider>
  );
}

const mapStateToProps = (state: { profile: any; sidebar: any }) => ({
  rightDashboardSidebar: state.sidebar.rightSidebarDashboardVisible,
  rightEventSidebar: state.sidebar.rightSidebarEventVisible,
  profile: state.profile.data && state.profile.data.name,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      showAlert: ShowAlert,
      hideAlert: HideAlert,
      signIn: signIn,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
