import React, { createContext, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { setAccessToken, setAuthenticated } from "../../store/actions/auth";

interface AuthContextProps {
  isAuthenticated: boolean;
  login: (token: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<any> = ({ children }: any) => {
  const auth = useSelector(
    (state: { auth: { isAuthenticated: boolean } }) => state.auth
  );

  const storedToken = localStorage.getItem("accessToken");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAccessToken(storedToken ? storedToken : null));
    dispatch(setAuthenticated(storedToken ? true : false));
  }, [dispatch, storedToken]);

  const login = (token: string) => {
    // Set the token in local storage and mark the user as authenticated
    localStorage.setItem("accessToken", token);
    dispatch(setAccessToken(token));
    dispatch(setAuthenticated(true));
  };

  const logout = () => {
    // Remove the token from local storage and mark the user as not authenticated
    localStorage.removeItem("accessToken");
    dispatch(setAccessToken(null));
    dispatch(setAuthenticated(false));
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated: auth.isAuthenticated, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const PrivateRoute: React.FC<any> = ({ element, ...rest }) => {
  const auth = useAuth();

  if (!!auth.isAuthenticated) return element;

};
