// dataSaga.ts
import {
  call,
  put,
  all,
  takeLatest,
  takeEvery,
  select,
} from "redux-saga/effects";
import {
  fetchNotificationSuccess,
  fetchNotificationFailure,
  deleteNotificationSuccess,
  deleteNotificationFailure,
  readNotificationSuccess,
  readNotificationFailure,
  readSingleNotificationSuccess,
  readSingleNotificationFailure,
} from "../../actions/notifications";

import {
  FETCH_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION_REQUEST,
  READ_NOTIFICATION_REQUEST,
  READ_SINGLE_NOTIFICATION_REQUEST,
} from "../../../constants/redux";
import { commonApiCall } from "../../apiCaller";
import { ShowAlert } from "../../actions/alert";
import { getUserIdFromState } from "../../authUtils";
import { COMMON_ERROR_MESSAGE, COMMON_SAVE_ERROR_MESSAGE, COMMON_SUCCESSFULL_DELETE_MESSAGE } from "../../../constants/redux/messages";

type GeneratorReturnType<T> = Generator<any, T, any>;

const notificationPath = "";

function* fetchNotificationSaga(action: any): GeneratorReturnType<void> {
  try {
    
    const response = yield call(
      commonApiCall,
      `${notificationPath}/notifications`,
      "GET",
      {},
      {}
    );

    yield put(fetchNotificationSuccess(response));
  } catch (error: any) {
    yield put(fetchNotificationFailure(error.message));
    yield put(
      ShowAlert({
        showAlert: true,
        message: error.message || COMMON_ERROR_MESSAGE,
        type: "error",
      })
    );
  }
}

function* deleteNotificationSaga({ payload }: any): GeneratorReturnType<void> {
  try {
    
    const idsToDelete =
      typeof payload === "string"
        ? [payload]
        : payload.map((notification: { id: string }) => notification.id);

    const response = yield call(
      commonApiCall,
      `${notificationPath}/notifications`,
      "DELETE",
      idsToDelete ? { ids: idsToDelete } : {},
      {}
    );
    yield put(
      deleteNotificationSuccess(idsToDelete.length === 1 ? idsToDelete : [])
    );
    yield put(
      ShowAlert({
        showAlert: true,
        message: response.message || COMMON_SUCCESSFULL_DELETE_MESSAGE,
        type: "success",
      })
    );
  } catch (error: any) {
    yield put(deleteNotificationFailure(error.message));
    yield put(
      ShowAlert({
        showAlert: true,
        message: error.message || COMMON_SAVE_ERROR_MESSAGE,
        type: "error",
      })
    );
  }
}

function* readNotificationSaga(action: any): GeneratorReturnType<void> {
  try {
    
    const response = yield call(
      commonApiCall,
      `${notificationPath}/notifications/read`,
      "PUT",
      {},
      {}
    );

    yield put(readNotificationSuccess(response));
  } catch (error: any) {
    yield put(readNotificationFailure(error.message));
  }
}

function* readSingleNotificationSaga(action: any): GeneratorReturnType<void> {
  try {
    
    yield call(
      commonApiCall,
      `${notificationPath}/notifications/readOne/${action.payload.id}/${action.payload.status}`,
      "PUT",
      {},
      {}
    );
    yield put(
      readSingleNotificationSuccess({
        id: action.payload.id,
        status: action.payload.status,
      })
    );
  } catch (error: any) {
    yield put(readSingleNotificationFailure(error.message));
  }
}

function* watchFetchNotification() {
  yield takeLatest(FETCH_NOTIFICATION_REQUEST, fetchNotificationSaga);
}

function* watchDeleteNotification() {
  yield takeEvery(DELETE_NOTIFICATION_REQUEST, deleteNotificationSaga);
}

function* watchReadNotification() {
  yield takeEvery(READ_NOTIFICATION_REQUEST, readNotificationSaga);
}

function* watchReadSingleNotification() {
  yield takeEvery(READ_SINGLE_NOTIFICATION_REQUEST, readSingleNotificationSaga);
}

export function* notificationSaga() {
  yield all([
    watchFetchNotification(),
    watchDeleteNotification(),
    watchReadNotification(),
    watchReadSingleNotification(),
  ]);
}
