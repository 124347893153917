import { call, put, all, takeLatest, select } from "redux-saga/effects";
import {
  fetchGallerySuccess,
  fetchGalleryFailure,
} from "../../actions/galleries";

import { FETCH_GALLERY_REQUEST } from "../../../constants/redux";
import { commonApiCall } from "../../apiCaller";
import { getUserIdFromState } from "../../authUtils";
import { ShowAlert } from "../../actions/alert";
import { COMMON_ERROR_MESSAGE } from "../../../constants/redux/messages";

type GeneratorReturnType<T> = Generator<any, T, any>;

const dashboardUrl = "";

function* fetchGallerySaga(action: any): GeneratorReturnType<void> {
  
  try {
    const response = yield call(
      commonApiCall,
      `${dashboardUrl}/galleries`,
      "GET",
      {},
      {}
    );

    yield put(fetchGallerySuccess(response));
  } catch (error: any) {
    yield put(fetchGalleryFailure(error.message));
    yield put(
      ShowAlert({
        showAlert: true,
        message: error.message || COMMON_ERROR_MESSAGE,
        type: "error",
      })
    );
  }
}

function* watchFetchData() {
  yield takeLatest(FETCH_GALLERY_REQUEST, fetchGallerySaga);
}

export function* gallerySaga() {
  yield all([watchFetchData()]);
}
