import { responsiveFontSizes } from "./typography";

// ----------------------------------------------------------------------

export function overrides(theme: any) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          borderWidth: "2px",
        },
        "&:hover": {
          borderWidth: "2px",
        },
        outlined: {
          "&:hover": {
            borderWidth: 2,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow:
            "4px 4px 20px -4px rgba(0, 0, 0, 0.05), 0px 2px 12px -4px rgba(0, 0, 0, 0.10)",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins",
          fontWeight: "400",
          color: "#2F242C",
          fontSize: "24px",
          "& ::placeholder": {
            fontFamily: "Poppins",
            fontWeight: "400",
            color: "#6b626766",
            ...responsiveFontSizes({ sm: 14, md: 16, lg: 18 })
            //fontSize: "18px", // Adjust the placeholder font size as needed
          },
          '&.Mui-focused fieldset': {
            borderColor: '#4C8577 !important',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins",
          fontWeight: "400",
          color: "#2F242C",
          fontSize: "24px",
        },
      },
    },
  };
}
